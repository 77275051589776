import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CctvFragment = { __typename?: 'cctv', url: string | null, preview: string | null };

export type GateWithLastOpenFragment = { __typename?: 'gate', id: string, name: string, number: number | null, delay: number, cctv_preview_rate: number, opens: Array<{ __typename?: 'gate_open', created_at: import("dayjs").Dayjs }>, cctv: { __typename?: 'cctv', url: string | null, preview: string | null } | null };

export type GatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GatesQuery = { __typename?: 'query_root', gate: Array<{ __typename?: 'gate', id: string, name: string, number: number | null, delay: number, cctv_preview_rate: number, opens: Array<{ __typename?: 'gate_open', created_at: import("dayjs").Dayjs }>, cctv: { __typename?: 'cctv', url: string | null, preview: string | null } | null }> };

export const CctvFragmentDoc = gql`
    fragment Cctv on cctv {
  url
  preview
}
    `;
export const GateWithLastOpenFragmentDoc = gql`
    fragment GateWithLastOpen on gate {
  id
  name
  number
  delay
  opens(order_by: {created_at: desc}, limit: 1) {
    created_at
  }
  cctv {
    ...Cctv
  }
  cctv_preview_rate
}
    ${CctvFragmentDoc}`;
export const GatesDocument = gql`
    query Gates {
  gate(order_by: {number: asc}) {
    ...GateWithLastOpen
  }
}
    ${GateWithLastOpenFragmentDoc}`;

/**
 * __useGatesQuery__
 *
 * To run a query within a React component, call `useGatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGatesQuery(baseOptions?: Apollo.QueryHookOptions<GatesQuery, GatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GatesQuery, GatesQueryVariables>(GatesDocument, options);
      }
export function useGatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GatesQuery, GatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GatesQuery, GatesQueryVariables>(GatesDocument, options);
        }
export type GatesQueryHookResult = ReturnType<typeof useGatesQuery>;
export type GatesLazyQueryHookResult = ReturnType<typeof useGatesLazyQuery>;
export type GatesQueryResult = Apollo.QueryResult<GatesQuery, GatesQueryVariables>;
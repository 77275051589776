export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: number; output: number; }
  point: { input: any; output: any; }
  polygon: { input: any; output: any; }
  uuid: { input: string; output: string; }
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq: InputMaybe<Scalars['Int']['input']>;
  _gt: InputMaybe<Scalars['Int']['input']>;
  _gte: InputMaybe<Scalars['Int']['input']>;
  _in: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['Int']['input']>;
  _lte: InputMaybe<Scalars['Int']['input']>;
  _neq: InputMaybe<Scalars['Int']['input']>;
  _nin: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq: InputMaybe<Scalars['String']['input']>;
  _gt: InputMaybe<Scalars['String']['input']>;
  _gte: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike: InputMaybe<Scalars['String']['input']>;
  _in: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex: InputMaybe<Scalars['String']['input']>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like: InputMaybe<Scalars['String']['input']>;
  _lt: InputMaybe<Scalars['String']['input']>;
  _lte: InputMaybe<Scalars['String']['input']>;
  _neq: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike: InputMaybe<Scalars['String']['input']>;
  _nin: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq: InputMaybe<Scalars['bigint']['input']>;
  _gt: InputMaybe<Scalars['bigint']['input']>;
  _gte: InputMaybe<Scalars['bigint']['input']>;
  _in: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['bigint']['input']>;
  _lte: InputMaybe<Scalars['bigint']['input']>;
  _neq: InputMaybe<Scalars['bigint']['input']>;
  _nin: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "contact" */
export type Contact = {
  __typename?: 'contact';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  position: Maybe<Scalars['Int']['output']>;
  site: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "contact" */
export type ContactAggregate = {
  __typename?: 'contact_aggregate';
  aggregate: Maybe<ContactAggregateFields>;
  nodes: Array<Contact>;
};

/** aggregate fields of "contact" */
export type ContactAggregateFields = {
  __typename?: 'contact_aggregate_fields';
  avg: Maybe<ContactAvgFields>;
  count: Scalars['Int']['output'];
  max: Maybe<ContactMaxFields>;
  min: Maybe<ContactMinFields>;
  stddev: Maybe<ContactStddevFields>;
  stddev_pop: Maybe<ContactStddevPopFields>;
  stddev_samp: Maybe<ContactStddevSampFields>;
  sum: Maybe<ContactSumFields>;
  var_pop: Maybe<ContactVarPopFields>;
  var_samp: Maybe<ContactVarSampFields>;
  variance: Maybe<ContactVarianceFields>;
};


/** aggregate fields of "contact" */
export type ContactAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<ContactSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ContactAvgFields = {
  __typename?: 'contact_avg_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "contact". All fields are combined with a logical 'AND'. */
export type ContactBoolExp = {
  _and: InputMaybe<Array<ContactBoolExp>>;
  _not: InputMaybe<ContactBoolExp>;
  _or: InputMaybe<Array<ContactBoolExp>>;
  description: InputMaybe<StringComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  name: InputMaybe<StringComparisonExp>;
  phone: InputMaybe<StringComparisonExp>;
  position: InputMaybe<IntComparisonExp>;
  site: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ContactMaxFields = {
  __typename?: 'contact_max_fields';
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  position: Maybe<Scalars['Int']['output']>;
  site: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ContactMinFields = {
  __typename?: 'contact_min_fields';
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  position: Maybe<Scalars['Int']['output']>;
  site: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "contact". */
export type ContactOrderBy = {
  description: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  name: InputMaybe<OrderBy>;
  phone: InputMaybe<OrderBy>;
  position: InputMaybe<OrderBy>;
  site: InputMaybe<OrderBy>;
};

/** select columns of table "contact" */
export enum ContactSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  Site = 'site'
}

/** aggregate stddev on columns */
export type ContactStddevFields = {
  __typename?: 'contact_stddev_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ContactStddevPopFields = {
  __typename?: 'contact_stddev_pop_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ContactStddevSampFields = {
  __typename?: 'contact_stddev_samp_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "contact" */
export type ContactStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ContactStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactStreamCursorValueInput = {
  description: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['Int']['input']>;
  site: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ContactSumFields = {
  __typename?: 'contact_sum_fields';
  position: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type ContactVarPopFields = {
  __typename?: 'contact_var_pop_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ContactVarSampFields = {
  __typename?: 'contact_var_samp_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ContactVarianceFields = {
  __typename?: 'contact_variance_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "gate" */
export type Gate = {
  __typename?: 'gate';
  coordinates: Maybe<Scalars['point']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  number: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  opens: Array<GateOpen>;
};


/** columns and relationships of "gate" */
export type GateOpensArgs = {
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};

/** aggregated selection of "gate" */
export type GateAggregate = {
  __typename?: 'gate_aggregate';
  aggregate: Maybe<GateAggregateFields>;
  nodes: Array<Gate>;
};

/** aggregate fields of "gate" */
export type GateAggregateFields = {
  __typename?: 'gate_aggregate_fields';
  avg: Maybe<GateAvgFields>;
  count: Scalars['Int']['output'];
  max: Maybe<GateMaxFields>;
  min: Maybe<GateMinFields>;
  stddev: Maybe<GateStddevFields>;
  stddev_pop: Maybe<GateStddevPopFields>;
  stddev_samp: Maybe<GateStddevSampFields>;
  sum: Maybe<GateSumFields>;
  var_pop: Maybe<GateVarPopFields>;
  var_samp: Maybe<GateVarSampFields>;
  variance: Maybe<GateVarianceFields>;
};


/** aggregate fields of "gate" */
export type GateAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<GateSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type GateAvgFields = {
  __typename?: 'gate_avg_fields';
  number: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "gate". All fields are combined with a logical 'AND'. */
export type GateBoolExp = {
  _and: InputMaybe<Array<GateBoolExp>>;
  _not: InputMaybe<GateBoolExp>;
  _or: InputMaybe<Array<GateBoolExp>>;
  coordinates: InputMaybe<PointComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  name: InputMaybe<StringComparisonExp>;
  number: InputMaybe<IntComparisonExp>;
  opens: InputMaybe<GateOpenBoolExp>;
};

/** aggregate max on columns */
export type GateMaxFields = {
  __typename?: 'gate_max_fields';
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type GateMinFields = {
  __typename?: 'gate_min_fields';
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "gate_open" */
export type GateOpen = {
  __typename?: 'gate_open';
  /** An object relationship */
  gate: Gate;
  id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "gate_open" */
export type GateOpenAggregateOrderBy = {
  count: InputMaybe<OrderBy>;
  max: InputMaybe<GateOpenMaxOrderBy>;
  min: InputMaybe<GateOpenMinOrderBy>;
};

/** Boolean expression to filter rows from the table "gate_open". All fields are combined with a logical 'AND'. */
export type GateOpenBoolExp = {
  _and: InputMaybe<Array<GateOpenBoolExp>>;
  _not: InputMaybe<GateOpenBoolExp>;
  _or: InputMaybe<Array<GateOpenBoolExp>>;
  gate: InputMaybe<GateBoolExp>;
  id: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "gate_open" */
export type GateOpenMaxOrderBy = {
  id: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "gate_open" */
export type GateOpenMinOrderBy = {
  id: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "gate_open". */
export type GateOpenOrderBy = {
  gate: InputMaybe<GateOrderBy>;
  id: InputMaybe<OrderBy>;
};

/** select columns of table "gate_open" */
export enum GateOpenSelectColumn {
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "gate_open" */
export type GateOpenStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GateOpenStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GateOpenStreamCursorValueInput = {
  id: InputMaybe<Scalars['uuid']['input']>;
};

/** Ordering options when selecting data from "gate". */
export type GateOrderBy = {
  coordinates: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  name: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
  opens_aggregate: InputMaybe<GateOpenAggregateOrderBy>;
};

/** select columns of table "gate" */
export enum GateSelectColumn {
  /** column name */
  Coordinates = 'coordinates',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number'
}

/** aggregate stddev on columns */
export type GateStddevFields = {
  __typename?: 'gate_stddev_fields';
  number: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type GateStddevPopFields = {
  __typename?: 'gate_stddev_pop_fields';
  number: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type GateStddevSampFields = {
  __typename?: 'gate_stddev_samp_fields';
  number: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "gate" */
export type GateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GateStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GateStreamCursorValueInput = {
  coordinates: InputMaybe<Scalars['point']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  number: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type GateSumFields = {
  __typename?: 'gate_sum_fields';
  number: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type GateVarPopFields = {
  __typename?: 'gate_var_pop_fields';
  number: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type GateVarSampFields = {
  __typename?: 'gate_var_samp_fields';
  number: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GateVarianceFields = {
  __typename?: 'gate_variance_fields';
  number: Maybe<Scalars['Float']['output']>;
};

export type JsonbCastExp = {
  String: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains: InputMaybe<Scalars['jsonb']['input']>;
  _eq: InputMaybe<Scalars['jsonb']['input']>;
  _gt: InputMaybe<Scalars['jsonb']['input']>;
  _gte: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any: InputMaybe<Array<Scalars['String']['input']>>;
  _in: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['jsonb']['input']>;
  _lte: InputMaybe<Scalars['jsonb']['input']>;
  _neq: InputMaybe<Scalars['jsonb']['input']>;
  _nin: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** Земельные участки */
export type Land = {
  __typename?: 'land';
  coordinates: Maybe<Scalars['point']['output']>;
  id: Scalars['uuid']['output'];
  number: Maybe<Scalars['String']['output']>;
  number_integer: Maybe<Scalars['Int']['output']>;
  polygon: Maybe<Scalars['polygon']['output']>;
  /** An object relationship */
  street: Street;
  street_id: Scalars['uuid']['output'];
};

/** aggregated selection of "land" */
export type LandAggregate = {
  __typename?: 'land_aggregate';
  aggregate: Maybe<LandAggregateFields>;
  nodes: Array<Land>;
};

export type LandAggregateBoolExp = {
  count: InputMaybe<LandAggregateBoolExpCount>;
};

export type LandAggregateBoolExpCount = {
  arguments: InputMaybe<Array<LandSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<LandBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "land" */
export type LandAggregateFields = {
  __typename?: 'land_aggregate_fields';
  avg: Maybe<LandAvgFields>;
  count: Scalars['Int']['output'];
  max: Maybe<LandMaxFields>;
  min: Maybe<LandMinFields>;
  stddev: Maybe<LandStddevFields>;
  stddev_pop: Maybe<LandStddevPopFields>;
  stddev_samp: Maybe<LandStddevSampFields>;
  sum: Maybe<LandSumFields>;
  var_pop: Maybe<LandVarPopFields>;
  var_samp: Maybe<LandVarSampFields>;
  variance: Maybe<LandVarianceFields>;
};


/** aggregate fields of "land" */
export type LandAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<LandSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "land" */
export type LandAggregateOrderBy = {
  avg: InputMaybe<LandAvgOrderBy>;
  count: InputMaybe<OrderBy>;
  max: InputMaybe<LandMaxOrderBy>;
  min: InputMaybe<LandMinOrderBy>;
  stddev: InputMaybe<LandStddevOrderBy>;
  stddev_pop: InputMaybe<LandStddevPopOrderBy>;
  stddev_samp: InputMaybe<LandStddevSampOrderBy>;
  sum: InputMaybe<LandSumOrderBy>;
  var_pop: InputMaybe<LandVarPopOrderBy>;
  var_samp: InputMaybe<LandVarSampOrderBy>;
  variance: InputMaybe<LandVarianceOrderBy>;
};

/** aggregate avg on columns */
export type LandAvgFields = {
  __typename?: 'land_avg_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "land" */
export type LandAvgOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "land". All fields are combined with a logical 'AND'. */
export type LandBoolExp = {
  _and: InputMaybe<Array<LandBoolExp>>;
  _not: InputMaybe<LandBoolExp>;
  _or: InputMaybe<Array<LandBoolExp>>;
  coordinates: InputMaybe<PointComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  number: InputMaybe<StringComparisonExp>;
  number_integer: InputMaybe<IntComparisonExp>;
  polygon: InputMaybe<PolygonComparisonExp>;
  street: InputMaybe<StreetBoolExp>;
  street_id: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type LandMaxFields = {
  __typename?: 'land_max_fields';
  id: Maybe<Scalars['uuid']['output']>;
  number: Maybe<Scalars['String']['output']>;
  number_integer: Maybe<Scalars['Int']['output']>;
  street_id: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "land" */
export type LandMaxOrderBy = {
  id: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
  number_integer: InputMaybe<OrderBy>;
  street_id: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LandMinFields = {
  __typename?: 'land_min_fields';
  id: Maybe<Scalars['uuid']['output']>;
  number: Maybe<Scalars['String']['output']>;
  number_integer: Maybe<Scalars['Int']['output']>;
  street_id: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "land" */
export type LandMinOrderBy = {
  id: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
  number_integer: InputMaybe<OrderBy>;
  street_id: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "land". */
export type LandOrderBy = {
  coordinates: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
  number_integer: InputMaybe<OrderBy>;
  polygon: InputMaybe<OrderBy>;
  street: InputMaybe<StreetOrderBy>;
  street_id: InputMaybe<OrderBy>;
};

/** select columns of table "land" */
export enum LandSelectColumn {
  /** column name */
  Coordinates = 'coordinates',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  NumberInteger = 'number_integer',
  /** column name */
  Polygon = 'polygon',
  /** column name */
  StreetId = 'street_id'
}

/** aggregate stddev on columns */
export type LandStddevFields = {
  __typename?: 'land_stddev_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "land" */
export type LandStddevOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LandStddevPopFields = {
  __typename?: 'land_stddev_pop_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "land" */
export type LandStddevPopOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LandStddevSampFields = {
  __typename?: 'land_stddev_samp_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "land" */
export type LandStddevSampOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "land" */
export type LandStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LandStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LandStreamCursorValueInput = {
  coordinates: InputMaybe<Scalars['point']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  number: InputMaybe<Scalars['String']['input']>;
  number_integer: InputMaybe<Scalars['Int']['input']>;
  polygon: InputMaybe<Scalars['polygon']['input']>;
  street_id: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type LandSumFields = {
  __typename?: 'land_sum_fields';
  number_integer: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "land" */
export type LandSumOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type LandVarPopFields = {
  __typename?: 'land_var_pop_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "land" */
export type LandVarPopOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LandVarSampFields = {
  __typename?: 'land_var_samp_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "land" */
export type LandVarSampOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LandVarianceFields = {
  __typename?: 'land_variance_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "land" */
export type LandVarianceOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** execute VOLATILE function "open_gate" which returns "gate_open" */
  open_gate: Array<GateOpen>;
};


/** mutation root */
export type MutationRootOpenGateArgs = {
  args: OpenGateArgs;
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq: InputMaybe<Scalars['numeric']['input']>;
  _gt: InputMaybe<Scalars['numeric']['input']>;
  _gte: InputMaybe<Scalars['numeric']['input']>;
  _in: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['numeric']['input']>;
  _lte: InputMaybe<Scalars['numeric']['input']>;
  _neq: InputMaybe<Scalars['numeric']['input']>;
  _nin: InputMaybe<Array<Scalars['numeric']['input']>>;
};

export type OpenGateArgs = {
  gate: InputMaybe<Scalars['uuid']['input']>;
  token: InputMaybe<Scalars['String']['input']>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Boolean expression to compare columns of type "point". All fields are combined with logical 'AND'. */
export type PointComparisonExp = {
  _eq: InputMaybe<Scalars['point']['input']>;
  _gt: InputMaybe<Scalars['point']['input']>;
  _gte: InputMaybe<Scalars['point']['input']>;
  _in: InputMaybe<Array<Scalars['point']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['point']['input']>;
  _lte: InputMaybe<Scalars['point']['input']>;
  _neq: InputMaybe<Scalars['point']['input']>;
  _nin: InputMaybe<Array<Scalars['point']['input']>>;
};

/** Boolean expression to compare columns of type "polygon". All fields are combined with logical 'AND'. */
export type PolygonComparisonExp = {
  _eq: InputMaybe<Scalars['polygon']['input']>;
  _gt: InputMaybe<Scalars['polygon']['input']>;
  _gte: InputMaybe<Scalars['polygon']['input']>;
  _in: InputMaybe<Array<Scalars['polygon']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['polygon']['input']>;
  _lte: InputMaybe<Scalars['polygon']['input']>;
  _neq: InputMaybe<Scalars['polygon']['input']>;
  _nin: InputMaybe<Array<Scalars['polygon']['input']>>;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: ContactAggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk: Maybe<Contact>;
  /** fetch data from the table: "gate" */
  gate: Array<Gate>;
  /** fetch aggregated fields from the table: "gate" */
  gate_aggregate: GateAggregate;
  /** fetch data from the table: "gate" using primary key columns */
  gate_by_pk: Maybe<Gate>;
  /** fetch data from the table: "gate_open" */
  gate_open: Array<GateOpen>;
  /** fetch data from the table: "gate_open" using primary key columns */
  gate_open_by_pk: Maybe<GateOpen>;
  /** fetch data from the table: "land" */
  land: Array<Land>;
  /** fetch aggregated fields from the table: "land" */
  land_aggregate: LandAggregate;
  /** fetch data from the table: "land" using primary key columns */
  land_by_pk: Maybe<Land>;
  /** fetch data from the table: "street" */
  street: Array<Street>;
  /** fetch aggregated fields from the table: "street" */
  street_aggregate: StreetAggregate;
  /** fetch data from the table: "street" using primary key columns */
  street_by_pk: Maybe<Street>;
  /** fetch data from the table: "target" */
  target: Array<Target>;
  /** fetch data from the table: "target" using primary key columns */
  target_by_pk: Maybe<Target>;
  /** fetch data from the table: "target_payment" */
  target_payment: Array<TargetPayment>;
  /** fetch data from the table: "target_payment" using primary key columns */
  target_payment_by_pk: Maybe<TargetPayment>;
};


export type QueryRootContactArgs = {
  distinct_on: InputMaybe<Array<ContactSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<ContactOrderBy>>;
  where: InputMaybe<ContactBoolExp>;
};


export type QueryRootContactAggregateArgs = {
  distinct_on: InputMaybe<Array<ContactSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<ContactOrderBy>>;
  where: InputMaybe<ContactBoolExp>;
};


export type QueryRootContactByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootGateArgs = {
  distinct_on: InputMaybe<Array<GateSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOrderBy>>;
  where: InputMaybe<GateBoolExp>;
};


export type QueryRootGateAggregateArgs = {
  distinct_on: InputMaybe<Array<GateSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOrderBy>>;
  where: InputMaybe<GateBoolExp>;
};


export type QueryRootGateByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootGateOpenArgs = {
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};


export type QueryRootGateOpenByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootLandArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};


export type QueryRootLandAggregateArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};


export type QueryRootLandByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootStreetArgs = {
  distinct_on: InputMaybe<Array<StreetSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<StreetOrderBy>>;
  where: InputMaybe<StreetBoolExp>;
};


export type QueryRootStreetAggregateArgs = {
  distinct_on: InputMaybe<Array<StreetSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<StreetOrderBy>>;
  where: InputMaybe<StreetBoolExp>;
};


export type QueryRootStreetByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootTargetArgs = {
  distinct_on: InputMaybe<Array<TargetSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<TargetOrderBy>>;
  where: InputMaybe<TargetBoolExp>;
};


export type QueryRootTargetByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootTargetPaymentArgs = {
  distinct_on: InputMaybe<Array<TargetPaymentSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<TargetPaymentOrderBy>>;
  where: InputMaybe<TargetPaymentBoolExp>;
};


export type QueryRootTargetPaymentByPkArgs = {
  id: Scalars['uuid']['input'];
};

/** Улицы */
export type Street = {
  __typename?: 'street';
  id: Scalars['uuid']['output'];
  /** An array relationship */
  lands: Array<Land>;
  /** An aggregate relationship */
  lands_aggregate: LandAggregate;
  name: Scalars['String']['output'];
};


/** Улицы */
export type StreetLandsArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};


/** Улицы */
export type StreetLandsAggregateArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};

/** aggregated selection of "street" */
export type StreetAggregate = {
  __typename?: 'street_aggregate';
  aggregate: Maybe<StreetAggregateFields>;
  nodes: Array<Street>;
};

/** aggregate fields of "street" */
export type StreetAggregateFields = {
  __typename?: 'street_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<StreetMaxFields>;
  min: Maybe<StreetMinFields>;
};


/** aggregate fields of "street" */
export type StreetAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<StreetSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "street". All fields are combined with a logical 'AND'. */
export type StreetBoolExp = {
  _and: InputMaybe<Array<StreetBoolExp>>;
  _not: InputMaybe<StreetBoolExp>;
  _or: InputMaybe<Array<StreetBoolExp>>;
  id: InputMaybe<UuidComparisonExp>;
  lands: InputMaybe<LandBoolExp>;
  lands_aggregate: InputMaybe<LandAggregateBoolExp>;
  name: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type StreetMaxFields = {
  __typename?: 'street_max_fields';
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type StreetMinFields = {
  __typename?: 'street_min_fields';
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "street". */
export type StreetOrderBy = {
  id: InputMaybe<OrderBy>;
  lands_aggregate: InputMaybe<LandAggregateOrderBy>;
  name: InputMaybe<OrderBy>;
};

/** select columns of table "street" */
export enum StreetSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "street" */
export type StreetStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: StreetStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StreetStreamCursorValueInput = {
  id: InputMaybe<Scalars['uuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: ContactAggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk: Maybe<Contact>;
  /** fetch data from the table in a streaming manner: "contact" */
  contact_stream: Array<Contact>;
  /** fetch data from the table: "gate" */
  gate: Array<Gate>;
  /** fetch aggregated fields from the table: "gate" */
  gate_aggregate: GateAggregate;
  /** fetch data from the table: "gate" using primary key columns */
  gate_by_pk: Maybe<Gate>;
  /** fetch data from the table: "gate_open" */
  gate_open: Array<GateOpen>;
  /** fetch data from the table: "gate_open" using primary key columns */
  gate_open_by_pk: Maybe<GateOpen>;
  /** fetch data from the table in a streaming manner: "gate_open" */
  gate_open_stream: Array<GateOpen>;
  /** fetch data from the table in a streaming manner: "gate" */
  gate_stream: Array<Gate>;
  /** fetch data from the table: "land" */
  land: Array<Land>;
  /** fetch aggregated fields from the table: "land" */
  land_aggregate: LandAggregate;
  /** fetch data from the table: "land" using primary key columns */
  land_by_pk: Maybe<Land>;
  /** fetch data from the table in a streaming manner: "land" */
  land_stream: Array<Land>;
  /** fetch data from the table: "street" */
  street: Array<Street>;
  /** fetch aggregated fields from the table: "street" */
  street_aggregate: StreetAggregate;
  /** fetch data from the table: "street" using primary key columns */
  street_by_pk: Maybe<Street>;
  /** fetch data from the table in a streaming manner: "street" */
  street_stream: Array<Street>;
  /** fetch data from the table: "target" */
  target: Array<Target>;
  /** fetch data from the table: "target" using primary key columns */
  target_by_pk: Maybe<Target>;
  /** fetch data from the table: "target_payment" */
  target_payment: Array<TargetPayment>;
  /** fetch data from the table: "target_payment" using primary key columns */
  target_payment_by_pk: Maybe<TargetPayment>;
  /** fetch data from the table in a streaming manner: "target_payment" */
  target_payment_stream: Array<TargetPayment>;
  /** fetch data from the table in a streaming manner: "target" */
  target_stream: Array<Target>;
};


export type SubscriptionRootContactArgs = {
  distinct_on: InputMaybe<Array<ContactSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<ContactOrderBy>>;
  where: InputMaybe<ContactBoolExp>;
};


export type SubscriptionRootContactAggregateArgs = {
  distinct_on: InputMaybe<Array<ContactSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<ContactOrderBy>>;
  where: InputMaybe<ContactBoolExp>;
};


export type SubscriptionRootContactByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootContactStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContactStreamCursorInput>>;
  where: InputMaybe<ContactBoolExp>;
};


export type SubscriptionRootGateArgs = {
  distinct_on: InputMaybe<Array<GateSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOrderBy>>;
  where: InputMaybe<GateBoolExp>;
};


export type SubscriptionRootGateAggregateArgs = {
  distinct_on: InputMaybe<Array<GateSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOrderBy>>;
  where: InputMaybe<GateBoolExp>;
};


export type SubscriptionRootGateByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootGateOpenArgs = {
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};


export type SubscriptionRootGateOpenByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootGateOpenStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GateOpenStreamCursorInput>>;
  where: InputMaybe<GateOpenBoolExp>;
};


export type SubscriptionRootGateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GateStreamCursorInput>>;
  where: InputMaybe<GateBoolExp>;
};


export type SubscriptionRootLandArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};


export type SubscriptionRootLandAggregateArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};


export type SubscriptionRootLandByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootLandStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LandStreamCursorInput>>;
  where: InputMaybe<LandBoolExp>;
};


export type SubscriptionRootStreetArgs = {
  distinct_on: InputMaybe<Array<StreetSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<StreetOrderBy>>;
  where: InputMaybe<StreetBoolExp>;
};


export type SubscriptionRootStreetAggregateArgs = {
  distinct_on: InputMaybe<Array<StreetSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<StreetOrderBy>>;
  where: InputMaybe<StreetBoolExp>;
};


export type SubscriptionRootStreetByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootStreetStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StreetStreamCursorInput>>;
  where: InputMaybe<StreetBoolExp>;
};


export type SubscriptionRootTargetArgs = {
  distinct_on: InputMaybe<Array<TargetSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<TargetOrderBy>>;
  where: InputMaybe<TargetBoolExp>;
};


export type SubscriptionRootTargetByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootTargetPaymentArgs = {
  distinct_on: InputMaybe<Array<TargetPaymentSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<TargetPaymentOrderBy>>;
  where: InputMaybe<TargetPaymentBoolExp>;
};


export type SubscriptionRootTargetPaymentByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootTargetPaymentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TargetPaymentStreamCursorInput>>;
  where: InputMaybe<TargetPaymentBoolExp>;
};


export type SubscriptionRootTargetStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TargetStreamCursorInput>>;
  where: InputMaybe<TargetBoolExp>;
};

/** Статьи целевых взносов */
export type Target = {
  __typename?: 'target';
  id: Scalars['uuid']['output'];
  lands: Scalars['jsonb']['output'];
  name: Scalars['String']['output'];
  payer_amount: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  payments: Array<TargetPayment>;
};


/** Статьи целевых взносов */
export type TargetLandsArgs = {
  path: InputMaybe<Scalars['String']['input']>;
};


/** Статьи целевых взносов */
export type TargetPaymentsArgs = {
  distinct_on: InputMaybe<Array<TargetPaymentSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<TargetPaymentOrderBy>>;
  where: InputMaybe<TargetPaymentBoolExp>;
};

/** Boolean expression to filter rows from the table "target". All fields are combined with a logical 'AND'. */
export type TargetBoolExp = {
  _and: InputMaybe<Array<TargetBoolExp>>;
  _not: InputMaybe<TargetBoolExp>;
  _or: InputMaybe<Array<TargetBoolExp>>;
  id: InputMaybe<UuidComparisonExp>;
  lands: InputMaybe<JsonbComparisonExp>;
  name: InputMaybe<StringComparisonExp>;
  payer_amount: InputMaybe<NumericComparisonExp>;
  payments: InputMaybe<TargetPaymentBoolExp>;
};

/** Ordering options when selecting data from "target". */
export type TargetOrderBy = {
  id: InputMaybe<OrderBy>;
  lands: InputMaybe<OrderBy>;
  name: InputMaybe<OrderBy>;
  payer_amount: InputMaybe<OrderBy>;
  payments_aggregate: InputMaybe<TargetPaymentAggregateOrderBy>;
};

/** Целевые взносы */
export type TargetPayment = {
  __typename?: 'target_payment';
  amount: Scalars['bigint']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  land: Maybe<Land>;
  land_id: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  target: Target;
  target_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "target_payment" */
export type TargetPaymentAggregateOrderBy = {
  avg: InputMaybe<TargetPaymentAvgOrderBy>;
  count: InputMaybe<OrderBy>;
  max: InputMaybe<TargetPaymentMaxOrderBy>;
  min: InputMaybe<TargetPaymentMinOrderBy>;
  stddev: InputMaybe<TargetPaymentStddevOrderBy>;
  stddev_pop: InputMaybe<TargetPaymentStddevPopOrderBy>;
  stddev_samp: InputMaybe<TargetPaymentStddevSampOrderBy>;
  sum: InputMaybe<TargetPaymentSumOrderBy>;
  var_pop: InputMaybe<TargetPaymentVarPopOrderBy>;
  var_samp: InputMaybe<TargetPaymentVarSampOrderBy>;
  variance: InputMaybe<TargetPaymentVarianceOrderBy>;
};

/** order by avg() on columns of table "target_payment" */
export type TargetPaymentAvgOrderBy = {
  amount: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "target_payment". All fields are combined with a logical 'AND'. */
export type TargetPaymentBoolExp = {
  _and: InputMaybe<Array<TargetPaymentBoolExp>>;
  _not: InputMaybe<TargetPaymentBoolExp>;
  _or: InputMaybe<Array<TargetPaymentBoolExp>>;
  amount: InputMaybe<BigintComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  land: InputMaybe<LandBoolExp>;
  land_id: InputMaybe<UuidComparisonExp>;
  target: InputMaybe<TargetBoolExp>;
  target_id: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "target_payment" */
export type TargetPaymentMaxOrderBy = {
  amount: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  land_id: InputMaybe<OrderBy>;
  target_id: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "target_payment" */
export type TargetPaymentMinOrderBy = {
  amount: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  land_id: InputMaybe<OrderBy>;
  target_id: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "target_payment". */
export type TargetPaymentOrderBy = {
  amount: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  land: InputMaybe<LandOrderBy>;
  land_id: InputMaybe<OrderBy>;
  target: InputMaybe<TargetOrderBy>;
  target_id: InputMaybe<OrderBy>;
};

/** select columns of table "target_payment" */
export enum TargetPaymentSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Id = 'id',
  /** column name */
  LandId = 'land_id',
  /** column name */
  TargetId = 'target_id'
}

/** order by stddev() on columns of table "target_payment" */
export type TargetPaymentStddevOrderBy = {
  amount: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "target_payment" */
export type TargetPaymentStddevPopOrderBy = {
  amount: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "target_payment" */
export type TargetPaymentStddevSampOrderBy = {
  amount: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "target_payment" */
export type TargetPaymentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TargetPaymentStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TargetPaymentStreamCursorValueInput = {
  amount: InputMaybe<Scalars['bigint']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  land_id: InputMaybe<Scalars['uuid']['input']>;
  target_id: InputMaybe<Scalars['uuid']['input']>;
};

/** order by sum() on columns of table "target_payment" */
export type TargetPaymentSumOrderBy = {
  amount: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "target_payment" */
export type TargetPaymentVarPopOrderBy = {
  amount: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "target_payment" */
export type TargetPaymentVarSampOrderBy = {
  amount: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "target_payment" */
export type TargetPaymentVarianceOrderBy = {
  amount: InputMaybe<OrderBy>;
};

/** select columns of table "target" */
export enum TargetSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Lands = 'lands',
  /** column name */
  Name = 'name',
  /** column name */
  PayerAmount = 'payer_amount'
}

/** Streaming cursor of the table "target" */
export type TargetStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TargetStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TargetStreamCursorValueInput = {
  id: InputMaybe<Scalars['uuid']['input']>;
  lands: InputMaybe<Scalars['jsonb']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  payer_amount: InputMaybe<Scalars['numeric']['input']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq: InputMaybe<Scalars['uuid']['input']>;
  _gt: InputMaybe<Scalars['uuid']['input']>;
  _gte: InputMaybe<Scalars['uuid']['input']>;
  _in: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['uuid']['input']>;
  _lte: InputMaybe<Scalars['uuid']['input']>;
  _neq: InputMaybe<Scalars['uuid']['input']>;
  _nin: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export const scalarTypePolicies = {};

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  date: { input: any; output: any; }
  numeric: { input: number; output: number; }
  point: { input: any; output: any; }
  polygon: { input: any; output: any; }
  timestamptz: { input: import("dayjs").Dayjs; output: import("dayjs").Dayjs; }
  uuid: { input: string; output: string; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq: InputMaybe<Scalars['Boolean']['input']>;
  _gt: InputMaybe<Scalars['Boolean']['input']>;
  _gte: InputMaybe<Scalars['Boolean']['input']>;
  _in: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['Boolean']['input']>;
  _lte: InputMaybe<Scalars['Boolean']['input']>;
  _neq: InputMaybe<Scalars['Boolean']['input']>;
  _nin: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq: InputMaybe<Scalars['Int']['input']>;
  _gt: InputMaybe<Scalars['Int']['input']>;
  _gte: InputMaybe<Scalars['Int']['input']>;
  _in: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['Int']['input']>;
  _lte: InputMaybe<Scalars['Int']['input']>;
  _neq: InputMaybe<Scalars['Int']['input']>;
  _nin: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq: InputMaybe<Scalars['String']['input']>;
  _gt: InputMaybe<Scalars['String']['input']>;
  _gte: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike: InputMaybe<Scalars['String']['input']>;
  _in: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex: InputMaybe<Scalars['String']['input']>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like: InputMaybe<Scalars['String']['input']>;
  _lt: InputMaybe<Scalars['String']['input']>;
  _lte: InputMaybe<Scalars['String']['input']>;
  _neq: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike: InputMaybe<Scalars['String']['input']>;
  _nin: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "account" */
export type Account = {
  __typename?: 'account';
  balance: Scalars['numeric']['output'];
  balance_at: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  number: Scalars['String']['output'];
  /** An array relationship */
  payments: Array<MemberPayment>;
  /** An aggregate relationship */
  payments_aggregate: MemberPaymentAggregate;
  /** An object relationship */
  person: Maybe<Person>;
};


/** columns and relationships of "account" */
export type AccountPaymentsArgs = {
  distinct_on: InputMaybe<Array<MemberPaymentSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MemberPaymentOrderBy>>;
  where: InputMaybe<MemberPaymentBoolExp>;
};


/** columns and relationships of "account" */
export type AccountPaymentsAggregateArgs = {
  distinct_on: InputMaybe<Array<MemberPaymentSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MemberPaymentOrderBy>>;
  where: InputMaybe<MemberPaymentBoolExp>;
};

/** aggregated selection of "account" */
export type AccountAggregate = {
  __typename?: 'account_aggregate';
  aggregate: Maybe<AccountAggregateFields>;
  nodes: Array<Account>;
};

export type AccountAggregateBoolExp = {
  count: InputMaybe<AccountAggregateBoolExpCount>;
};

export type AccountAggregateBoolExpCount = {
  arguments: InputMaybe<Array<AccountSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<AccountBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "account" */
export type AccountAggregateFields = {
  __typename?: 'account_aggregate_fields';
  avg: Maybe<AccountAvgFields>;
  count: Scalars['Int']['output'];
  max: Maybe<AccountMaxFields>;
  min: Maybe<AccountMinFields>;
  stddev: Maybe<AccountStddevFields>;
  stddev_pop: Maybe<AccountStddevPopFields>;
  stddev_samp: Maybe<AccountStddevSampFields>;
  sum: Maybe<AccountSumFields>;
  var_pop: Maybe<AccountVarPopFields>;
  var_samp: Maybe<AccountVarSampFields>;
  variance: Maybe<AccountVarianceFields>;
};


/** aggregate fields of "account" */
export type AccountAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<AccountSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "account" */
export type AccountAggregateOrderBy = {
  avg: InputMaybe<AccountAvgOrderBy>;
  count: InputMaybe<OrderBy>;
  max: InputMaybe<AccountMaxOrderBy>;
  min: InputMaybe<AccountMinOrderBy>;
  stddev: InputMaybe<AccountStddevOrderBy>;
  stddev_pop: InputMaybe<AccountStddevPopOrderBy>;
  stddev_samp: InputMaybe<AccountStddevSampOrderBy>;
  sum: InputMaybe<AccountSumOrderBy>;
  var_pop: InputMaybe<AccountVarPopOrderBy>;
  var_samp: InputMaybe<AccountVarSampOrderBy>;
  variance: InputMaybe<AccountVarianceOrderBy>;
};

/** aggregate avg on columns */
export type AccountAvgFields = {
  __typename?: 'account_avg_fields';
  balance: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "account" */
export type AccountAvgOrderBy = {
  balance: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type AccountBoolExp = {
  _and: InputMaybe<Array<AccountBoolExp>>;
  _not: InputMaybe<AccountBoolExp>;
  _or: InputMaybe<Array<AccountBoolExp>>;
  balance: InputMaybe<NumericComparisonExp>;
  balance_at: InputMaybe<TimestamptzComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  number: InputMaybe<StringComparisonExp>;
  payments: InputMaybe<MemberPaymentBoolExp>;
  payments_aggregate: InputMaybe<MemberPaymentAggregateBoolExp>;
  person: InputMaybe<PersonBoolExp>;
};

/** aggregate max on columns */
export type AccountMaxFields = {
  __typename?: 'account_max_fields';
  balance: Maybe<Scalars['numeric']['output']>;
  balance_at: Maybe<Scalars['timestamptz']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  number: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "account" */
export type AccountMaxOrderBy = {
  balance: InputMaybe<OrderBy>;
  balance_at: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountMinFields = {
  __typename?: 'account_min_fields';
  balance: Maybe<Scalars['numeric']['output']>;
  balance_at: Maybe<Scalars['timestamptz']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  number: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "account" */
export type AccountMinOrderBy = {
  balance: InputMaybe<OrderBy>;
  balance_at: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "account". */
export type AccountOrderBy = {
  balance: InputMaybe<OrderBy>;
  balance_at: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
  payments_aggregate: InputMaybe<MemberPaymentAggregateOrderBy>;
  person: InputMaybe<PersonOrderBy>;
};

/** select columns of table "account" */
export enum AccountSelectColumn {
  /** column name */
  Balance = 'balance',
  /** column name */
  BalanceAt = 'balance_at',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number'
}

/** aggregate stddev on columns */
export type AccountStddevFields = {
  __typename?: 'account_stddev_fields';
  balance: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "account" */
export type AccountStddevOrderBy = {
  balance: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountStddevPopFields = {
  __typename?: 'account_stddev_pop_fields';
  balance: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "account" */
export type AccountStddevPopOrderBy = {
  balance: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountStddevSampFields = {
  __typename?: 'account_stddev_samp_fields';
  balance: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "account" */
export type AccountStddevSampOrderBy = {
  balance: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "account" */
export type AccountStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AccountStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountStreamCursorValueInput = {
  balance: InputMaybe<Scalars['numeric']['input']>;
  balance_at: InputMaybe<Scalars['timestamptz']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  number: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AccountSumFields = {
  __typename?: 'account_sum_fields';
  balance: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "account" */
export type AccountSumOrderBy = {
  balance: InputMaybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type AccountVarPopFields = {
  __typename?: 'account_var_pop_fields';
  balance: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "account" */
export type AccountVarPopOrderBy = {
  balance: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountVarSampFields = {
  __typename?: 'account_var_samp_fields';
  balance: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "account" */
export type AccountVarSampOrderBy = {
  balance: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountVarianceFields = {
  __typename?: 'account_variance_fields';
  balance: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "account" */
export type AccountVarianceOrderBy = {
  balance: InputMaybe<OrderBy>;
};

/** columns and relationships of "cctv" */
export type Cctv = {
  __typename?: 'cctv';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  preview: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  url: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "cctv" */
export type CctvAggregate = {
  __typename?: 'cctv_aggregate';
  aggregate: Maybe<CctvAggregateFields>;
  nodes: Array<Cctv>;
};

/** aggregate fields of "cctv" */
export type CctvAggregateFields = {
  __typename?: 'cctv_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<CctvMaxFields>;
  min: Maybe<CctvMinFields>;
};


/** aggregate fields of "cctv" */
export type CctvAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<CctvSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "cctv". All fields are combined with a logical 'AND'. */
export type CctvBoolExp = {
  _and: InputMaybe<Array<CctvBoolExp>>;
  _not: InputMaybe<CctvBoolExp>;
  _or: InputMaybe<Array<CctvBoolExp>>;
  created_at: InputMaybe<TimestamptzComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  name: InputMaybe<StringComparisonExp>;
  preview: InputMaybe<StringComparisonExp>;
  updated_at: InputMaybe<TimestamptzComparisonExp>;
  url: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type CctvMaxFields = {
  __typename?: 'cctv_max_fields';
  created_at: Maybe<Scalars['timestamptz']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  preview: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['timestamptz']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CctvMinFields = {
  __typename?: 'cctv_min_fields';
  created_at: Maybe<Scalars['timestamptz']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  preview: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['timestamptz']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "cctv". */
export type CctvOrderBy = {
  created_at: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  name: InputMaybe<OrderBy>;
  preview: InputMaybe<OrderBy>;
  updated_at: InputMaybe<OrderBy>;
  url: InputMaybe<OrderBy>;
};

/** select columns of table "cctv" */
export enum CctvSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Preview = 'preview',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** Streaming cursor of the table "cctv" */
export type CctvStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CctvStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CctvStreamCursorValueInput = {
  created_at: InputMaybe<Scalars['timestamptz']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  preview: InputMaybe<Scalars['String']['input']>;
  updated_at: InputMaybe<Scalars['timestamptz']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "contact" */
export type Contact = {
  __typename?: 'contact';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  position: Maybe<Scalars['Int']['output']>;
  site: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "contact" */
export type ContactAggregate = {
  __typename?: 'contact_aggregate';
  aggregate: Maybe<ContactAggregateFields>;
  nodes: Array<Contact>;
};

/** aggregate fields of "contact" */
export type ContactAggregateFields = {
  __typename?: 'contact_aggregate_fields';
  avg: Maybe<ContactAvgFields>;
  count: Scalars['Int']['output'];
  max: Maybe<ContactMaxFields>;
  min: Maybe<ContactMinFields>;
  stddev: Maybe<ContactStddevFields>;
  stddev_pop: Maybe<ContactStddevPopFields>;
  stddev_samp: Maybe<ContactStddevSampFields>;
  sum: Maybe<ContactSumFields>;
  var_pop: Maybe<ContactVarPopFields>;
  var_samp: Maybe<ContactVarSampFields>;
  variance: Maybe<ContactVarianceFields>;
};


/** aggregate fields of "contact" */
export type ContactAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<ContactSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ContactAvgFields = {
  __typename?: 'contact_avg_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "contact". All fields are combined with a logical 'AND'. */
export type ContactBoolExp = {
  _and: InputMaybe<Array<ContactBoolExp>>;
  _not: InputMaybe<ContactBoolExp>;
  _or: InputMaybe<Array<ContactBoolExp>>;
  description: InputMaybe<StringComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  name: InputMaybe<StringComparisonExp>;
  phone: InputMaybe<StringComparisonExp>;
  position: InputMaybe<IntComparisonExp>;
  site: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ContactMaxFields = {
  __typename?: 'contact_max_fields';
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  position: Maybe<Scalars['Int']['output']>;
  site: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ContactMinFields = {
  __typename?: 'contact_min_fields';
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  position: Maybe<Scalars['Int']['output']>;
  site: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "contact". */
export type ContactOrderBy = {
  description: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  name: InputMaybe<OrderBy>;
  phone: InputMaybe<OrderBy>;
  position: InputMaybe<OrderBy>;
  site: InputMaybe<OrderBy>;
};

/** select columns of table "contact" */
export enum ContactSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  Site = 'site'
}

/** aggregate stddev on columns */
export type ContactStddevFields = {
  __typename?: 'contact_stddev_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ContactStddevPopFields = {
  __typename?: 'contact_stddev_pop_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ContactStddevSampFields = {
  __typename?: 'contact_stddev_samp_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "contact" */
export type ContactStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ContactStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactStreamCursorValueInput = {
  description: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['Int']['input']>;
  site: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ContactSumFields = {
  __typename?: 'contact_sum_fields';
  position: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type ContactVarPopFields = {
  __typename?: 'contact_var_pop_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ContactVarSampFields = {
  __typename?: 'contact_var_samp_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ContactVarianceFields = {
  __typename?: 'contact_variance_fields';
  position: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq: InputMaybe<Scalars['date']['input']>;
  _gt: InputMaybe<Scalars['date']['input']>;
  _gte: InputMaybe<Scalars['date']['input']>;
  _in: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['date']['input']>;
  _lte: InputMaybe<Scalars['date']['input']>;
  _neq: InputMaybe<Scalars['date']['input']>;
  _nin: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "gate" */
export type Gate = {
  __typename?: 'gate';
  /** An object relationship */
  cctv: Maybe<Cctv>;
  cctv_id: Maybe<Scalars['uuid']['output']>;
  cctv_preview_rate: Scalars['Int']['output'];
  coordinates: Maybe<Scalars['point']['output']>;
  created_at: Scalars['timestamptz']['output'];
  delay: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  number: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  opens: Array<GateOpen>;
  /** An aggregate relationship */
  opens_aggregate: GateOpenAggregate;
  phone: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "gate" */
export type GateOpensArgs = {
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};


/** columns and relationships of "gate" */
export type GateOpensAggregateArgs = {
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};

/** aggregated selection of "gate" */
export type GateAggregate = {
  __typename?: 'gate_aggregate';
  aggregate: Maybe<GateAggregateFields>;
  nodes: Array<Gate>;
};

/** aggregate fields of "gate" */
export type GateAggregateFields = {
  __typename?: 'gate_aggregate_fields';
  avg: Maybe<GateAvgFields>;
  count: Scalars['Int']['output'];
  max: Maybe<GateMaxFields>;
  min: Maybe<GateMinFields>;
  stddev: Maybe<GateStddevFields>;
  stddev_pop: Maybe<GateStddevPopFields>;
  stddev_samp: Maybe<GateStddevSampFields>;
  sum: Maybe<GateSumFields>;
  var_pop: Maybe<GateVarPopFields>;
  var_samp: Maybe<GateVarSampFields>;
  variance: Maybe<GateVarianceFields>;
};


/** aggregate fields of "gate" */
export type GateAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<GateSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type GateAvgFields = {
  __typename?: 'gate_avg_fields';
  cctv_preview_rate: Maybe<Scalars['Float']['output']>;
  delay: Maybe<Scalars['Float']['output']>;
  number: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "gate". All fields are combined with a logical 'AND'. */
export type GateBoolExp = {
  _and: InputMaybe<Array<GateBoolExp>>;
  _not: InputMaybe<GateBoolExp>;
  _or: InputMaybe<Array<GateBoolExp>>;
  cctv: InputMaybe<CctvBoolExp>;
  cctv_id: InputMaybe<UuidComparisonExp>;
  cctv_preview_rate: InputMaybe<IntComparisonExp>;
  coordinates: InputMaybe<PointComparisonExp>;
  created_at: InputMaybe<TimestamptzComparisonExp>;
  delay: InputMaybe<IntComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  name: InputMaybe<StringComparisonExp>;
  number: InputMaybe<IntComparisonExp>;
  opens: InputMaybe<GateOpenBoolExp>;
  opens_aggregate: InputMaybe<GateOpenAggregateBoolExp>;
  phone: InputMaybe<StringComparisonExp>;
  updated_at: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type GateMaxFields = {
  __typename?: 'gate_max_fields';
  cctv_id: Maybe<Scalars['uuid']['output']>;
  cctv_preview_rate: Maybe<Scalars['Int']['output']>;
  created_at: Maybe<Scalars['timestamptz']['output']>;
  delay: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type GateMinFields = {
  __typename?: 'gate_min_fields';
  cctv_id: Maybe<Scalars['uuid']['output']>;
  cctv_preview_rate: Maybe<Scalars['Int']['output']>;
  created_at: Maybe<Scalars['timestamptz']['output']>;
  delay: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['timestamptz']['output']>;
};

/** columns and relationships of "gate_open" */
export type GateOpen = {
  __typename?: 'gate_open';
  comment: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  gate: Gate;
  gate_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  person: Maybe<Person>;
  /** An object relationship */
  reason: GateOpenReason;
  reason_id: Scalars['String']['output'];
  source: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "gate_open" */
export type GateOpenAggregate = {
  __typename?: 'gate_open_aggregate';
  aggregate: Maybe<GateOpenAggregateFields>;
  nodes: Array<GateOpen>;
};

export type GateOpenAggregateBoolExp = {
  count: InputMaybe<GateOpenAggregateBoolExpCount>;
};

export type GateOpenAggregateBoolExpCount = {
  arguments: InputMaybe<Array<GateOpenSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<GateOpenBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "gate_open" */
export type GateOpenAggregateFields = {
  __typename?: 'gate_open_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<GateOpenMaxFields>;
  min: Maybe<GateOpenMinFields>;
};


/** aggregate fields of "gate_open" */
export type GateOpenAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<GateOpenSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "gate_open" */
export type GateOpenAggregateOrderBy = {
  count: InputMaybe<OrderBy>;
  max: InputMaybe<GateOpenMaxOrderBy>;
  min: InputMaybe<GateOpenMinOrderBy>;
};

/** Boolean expression to filter rows from the table "gate_open". All fields are combined with a logical 'AND'. */
export type GateOpenBoolExp = {
  _and: InputMaybe<Array<GateOpenBoolExp>>;
  _not: InputMaybe<GateOpenBoolExp>;
  _or: InputMaybe<Array<GateOpenBoolExp>>;
  comment: InputMaybe<StringComparisonExp>;
  created_at: InputMaybe<TimestamptzComparisonExp>;
  gate: InputMaybe<GateBoolExp>;
  gate_id: InputMaybe<UuidComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  person: InputMaybe<PersonBoolExp>;
  reason: InputMaybe<GateOpenReasonBoolExp>;
  reason_id: InputMaybe<StringComparisonExp>;
  source: InputMaybe<StringComparisonExp>;
  updated_at: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type GateOpenMaxFields = {
  __typename?: 'gate_open_max_fields';
  comment: Maybe<Scalars['String']['output']>;
  created_at: Maybe<Scalars['timestamptz']['output']>;
  gate_id: Maybe<Scalars['uuid']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  reason_id: Maybe<Scalars['String']['output']>;
  source: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "gate_open" */
export type GateOpenMaxOrderBy = {
  comment: InputMaybe<OrderBy>;
  created_at: InputMaybe<OrderBy>;
  gate_id: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  reason_id: InputMaybe<OrderBy>;
  source: InputMaybe<OrderBy>;
  updated_at: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GateOpenMinFields = {
  __typename?: 'gate_open_min_fields';
  comment: Maybe<Scalars['String']['output']>;
  created_at: Maybe<Scalars['timestamptz']['output']>;
  gate_id: Maybe<Scalars['uuid']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  reason_id: Maybe<Scalars['String']['output']>;
  source: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "gate_open" */
export type GateOpenMinOrderBy = {
  comment: InputMaybe<OrderBy>;
  created_at: InputMaybe<OrderBy>;
  gate_id: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  reason_id: InputMaybe<OrderBy>;
  source: InputMaybe<OrderBy>;
  updated_at: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "gate_open". */
export type GateOpenOrderBy = {
  comment: InputMaybe<OrderBy>;
  created_at: InputMaybe<OrderBy>;
  gate: InputMaybe<GateOrderBy>;
  gate_id: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  person: InputMaybe<PersonOrderBy>;
  reason: InputMaybe<GateOpenReasonOrderBy>;
  reason_id: InputMaybe<OrderBy>;
  source: InputMaybe<OrderBy>;
  updated_at: InputMaybe<OrderBy>;
};

/** columns and relationships of "gate_open_reason" */
export type GateOpenReason = {
  __typename?: 'gate_open_reason';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "gate_open_reason" */
export type GateOpenReasonAggregate = {
  __typename?: 'gate_open_reason_aggregate';
  aggregate: Maybe<GateOpenReasonAggregateFields>;
  nodes: Array<GateOpenReason>;
};

/** aggregate fields of "gate_open_reason" */
export type GateOpenReasonAggregateFields = {
  __typename?: 'gate_open_reason_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<GateOpenReasonMaxFields>;
  min: Maybe<GateOpenReasonMinFields>;
};


/** aggregate fields of "gate_open_reason" */
export type GateOpenReasonAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<GateOpenReasonSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "gate_open_reason". All fields are combined with a logical 'AND'. */
export type GateOpenReasonBoolExp = {
  _and: InputMaybe<Array<GateOpenReasonBoolExp>>;
  _not: InputMaybe<GateOpenReasonBoolExp>;
  _or: InputMaybe<Array<GateOpenReasonBoolExp>>;
  created_at: InputMaybe<TimestamptzComparisonExp>;
  id: InputMaybe<StringComparisonExp>;
  name: InputMaybe<StringComparisonExp>;
  updated_at: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type GateOpenReasonMaxFields = {
  __typename?: 'gate_open_reason_max_fields';
  created_at: Maybe<Scalars['timestamptz']['output']>;
  id: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type GateOpenReasonMinFields = {
  __typename?: 'gate_open_reason_min_fields';
  created_at: Maybe<Scalars['timestamptz']['output']>;
  id: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['timestamptz']['output']>;
};

/** Ordering options when selecting data from "gate_open_reason". */
export type GateOpenReasonOrderBy = {
  created_at: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  name: InputMaybe<OrderBy>;
  updated_at: InputMaybe<OrderBy>;
};

/** select columns of table "gate_open_reason" */
export enum GateOpenReasonSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "gate_open_reason" */
export type GateOpenReasonStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GateOpenReasonStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GateOpenReasonStreamCursorValueInput = {
  created_at: InputMaybe<Scalars['timestamptz']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updated_at: InputMaybe<Scalars['timestamptz']['input']>;
};

/** select columns of table "gate_open" */
export enum GateOpenSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GateId = 'gate_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReasonId = 'reason_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "gate_open" */
export type GateOpenStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GateOpenStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GateOpenStreamCursorValueInput = {
  comment: InputMaybe<Scalars['String']['input']>;
  created_at: InputMaybe<Scalars['timestamptz']['input']>;
  gate_id: InputMaybe<Scalars['uuid']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  reason_id: InputMaybe<Scalars['String']['input']>;
  source: InputMaybe<Scalars['String']['input']>;
  updated_at: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Ordering options when selecting data from "gate". */
export type GateOrderBy = {
  cctv: InputMaybe<CctvOrderBy>;
  cctv_id: InputMaybe<OrderBy>;
  cctv_preview_rate: InputMaybe<OrderBy>;
  coordinates: InputMaybe<OrderBy>;
  created_at: InputMaybe<OrderBy>;
  delay: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  name: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
  opens_aggregate: InputMaybe<GateOpenAggregateOrderBy>;
  phone: InputMaybe<OrderBy>;
  updated_at: InputMaybe<OrderBy>;
};

/** select columns of table "gate" */
export enum GateSelectColumn {
  /** column name */
  CctvId = 'cctv_id',
  /** column name */
  CctvPreviewRate = 'cctv_preview_rate',
  /** column name */
  Coordinates = 'coordinates',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Delay = 'delay',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type GateStddevFields = {
  __typename?: 'gate_stddev_fields';
  cctv_preview_rate: Maybe<Scalars['Float']['output']>;
  delay: Maybe<Scalars['Float']['output']>;
  number: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type GateStddevPopFields = {
  __typename?: 'gate_stddev_pop_fields';
  cctv_preview_rate: Maybe<Scalars['Float']['output']>;
  delay: Maybe<Scalars['Float']['output']>;
  number: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type GateStddevSampFields = {
  __typename?: 'gate_stddev_samp_fields';
  cctv_preview_rate: Maybe<Scalars['Float']['output']>;
  delay: Maybe<Scalars['Float']['output']>;
  number: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "gate" */
export type GateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GateStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GateStreamCursorValueInput = {
  cctv_id: InputMaybe<Scalars['uuid']['input']>;
  cctv_preview_rate: InputMaybe<Scalars['Int']['input']>;
  coordinates: InputMaybe<Scalars['point']['input']>;
  created_at: InputMaybe<Scalars['timestamptz']['input']>;
  delay: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  number: InputMaybe<Scalars['Int']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  updated_at: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type GateSumFields = {
  __typename?: 'gate_sum_fields';
  cctv_preview_rate: Maybe<Scalars['Int']['output']>;
  delay: Maybe<Scalars['Int']['output']>;
  number: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type GateVarPopFields = {
  __typename?: 'gate_var_pop_fields';
  cctv_preview_rate: Maybe<Scalars['Float']['output']>;
  delay: Maybe<Scalars['Float']['output']>;
  number: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type GateVarSampFields = {
  __typename?: 'gate_var_samp_fields';
  cctv_preview_rate: Maybe<Scalars['Float']['output']>;
  delay: Maybe<Scalars['Float']['output']>;
  number: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GateVarianceFields = {
  __typename?: 'gate_variance_fields';
  cctv_preview_rate: Maybe<Scalars['Float']['output']>;
  delay: Maybe<Scalars['Float']['output']>;
  number: Maybe<Scalars['Float']['output']>;
};

/** Земельные участки */
export type Land = {
  __typename?: 'land';
  cadastral_number: Maybe<Scalars['String']['output']>;
  coordinates: Maybe<Scalars['point']['output']>;
  id: Scalars['uuid']['output'];
  number: Maybe<Scalars['String']['output']>;
  number_integer: Maybe<Scalars['Int']['output']>;
  polygon: Maybe<Scalars['polygon']['output']>;
  /** An object relationship */
  street: Street;
  street_id: Scalars['uuid']['output'];
};

/** aggregated selection of "land" */
export type LandAggregate = {
  __typename?: 'land_aggregate';
  aggregate: Maybe<LandAggregateFields>;
  nodes: Array<Land>;
};

export type LandAggregateBoolExp = {
  count: InputMaybe<LandAggregateBoolExpCount>;
};

export type LandAggregateBoolExpCount = {
  arguments: InputMaybe<Array<LandSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<LandBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "land" */
export type LandAggregateFields = {
  __typename?: 'land_aggregate_fields';
  avg: Maybe<LandAvgFields>;
  count: Scalars['Int']['output'];
  max: Maybe<LandMaxFields>;
  min: Maybe<LandMinFields>;
  stddev: Maybe<LandStddevFields>;
  stddev_pop: Maybe<LandStddevPopFields>;
  stddev_samp: Maybe<LandStddevSampFields>;
  sum: Maybe<LandSumFields>;
  var_pop: Maybe<LandVarPopFields>;
  var_samp: Maybe<LandVarSampFields>;
  variance: Maybe<LandVarianceFields>;
};


/** aggregate fields of "land" */
export type LandAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<LandSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "land" */
export type LandAggregateOrderBy = {
  avg: InputMaybe<LandAvgOrderBy>;
  count: InputMaybe<OrderBy>;
  max: InputMaybe<LandMaxOrderBy>;
  min: InputMaybe<LandMinOrderBy>;
  stddev: InputMaybe<LandStddevOrderBy>;
  stddev_pop: InputMaybe<LandStddevPopOrderBy>;
  stddev_samp: InputMaybe<LandStddevSampOrderBy>;
  sum: InputMaybe<LandSumOrderBy>;
  var_pop: InputMaybe<LandVarPopOrderBy>;
  var_samp: InputMaybe<LandVarSampOrderBy>;
  variance: InputMaybe<LandVarianceOrderBy>;
};

/** aggregate avg on columns */
export type LandAvgFields = {
  __typename?: 'land_avg_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "land" */
export type LandAvgOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "land". All fields are combined with a logical 'AND'. */
export type LandBoolExp = {
  _and: InputMaybe<Array<LandBoolExp>>;
  _not: InputMaybe<LandBoolExp>;
  _or: InputMaybe<Array<LandBoolExp>>;
  cadastral_number: InputMaybe<StringComparisonExp>;
  coordinates: InputMaybe<PointComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  number: InputMaybe<StringComparisonExp>;
  number_integer: InputMaybe<IntComparisonExp>;
  polygon: InputMaybe<PolygonComparisonExp>;
  street: InputMaybe<StreetBoolExp>;
  street_id: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type LandMaxFields = {
  __typename?: 'land_max_fields';
  cadastral_number: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  number: Maybe<Scalars['String']['output']>;
  number_integer: Maybe<Scalars['Int']['output']>;
  street_id: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "land" */
export type LandMaxOrderBy = {
  cadastral_number: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
  number_integer: InputMaybe<OrderBy>;
  street_id: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LandMinFields = {
  __typename?: 'land_min_fields';
  cadastral_number: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  number: Maybe<Scalars['String']['output']>;
  number_integer: Maybe<Scalars['Int']['output']>;
  street_id: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "land" */
export type LandMinOrderBy = {
  cadastral_number: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
  number_integer: InputMaybe<OrderBy>;
  street_id: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "land". */
export type LandOrderBy = {
  cadastral_number: InputMaybe<OrderBy>;
  coordinates: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  number: InputMaybe<OrderBy>;
  number_integer: InputMaybe<OrderBy>;
  polygon: InputMaybe<OrderBy>;
  street: InputMaybe<StreetOrderBy>;
  street_id: InputMaybe<OrderBy>;
};

/** select columns of table "land" */
export enum LandSelectColumn {
  /** column name */
  CadastralNumber = 'cadastral_number',
  /** column name */
  Coordinates = 'coordinates',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  NumberInteger = 'number_integer',
  /** column name */
  Polygon = 'polygon',
  /** column name */
  StreetId = 'street_id'
}

/** aggregate stddev on columns */
export type LandStddevFields = {
  __typename?: 'land_stddev_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "land" */
export type LandStddevOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LandStddevPopFields = {
  __typename?: 'land_stddev_pop_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "land" */
export type LandStddevPopOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LandStddevSampFields = {
  __typename?: 'land_stddev_samp_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "land" */
export type LandStddevSampOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "land" */
export type LandStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LandStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LandStreamCursorValueInput = {
  cadastral_number: InputMaybe<Scalars['String']['input']>;
  coordinates: InputMaybe<Scalars['point']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  number: InputMaybe<Scalars['String']['input']>;
  number_integer: InputMaybe<Scalars['Int']['input']>;
  polygon: InputMaybe<Scalars['polygon']['input']>;
  street_id: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type LandSumFields = {
  __typename?: 'land_sum_fields';
  number_integer: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "land" */
export type LandSumOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type LandVarPopFields = {
  __typename?: 'land_var_pop_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "land" */
export type LandVarPopOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LandVarSampFields = {
  __typename?: 'land_var_samp_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "land" */
export type LandVarSampOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LandVarianceFields = {
  __typename?: 'land_variance_fields';
  number_integer: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "land" */
export type LandVarianceOrderBy = {
  number_integer: InputMaybe<OrderBy>;
};

/** columns and relationships of "me" */
export type Me = {
  __typename?: 'me';
  balance: Maybe<Scalars['numeric']['output']>;
  balance_at: Maybe<Scalars['timestamptz']['output']>;
  birth_date: Maybe<Scalars['date']['output']>;
  entered_at: Maybe<Scalars['date']['output']>;
  firstname: Maybe<Scalars['String']['output']>;
  full_name: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  last_paid_amount: Maybe<Scalars['numeric']['output']>;
  last_paid_at: Maybe<Scalars['date']['output']>;
  lastname: Maybe<Scalars['String']['output']>;
  middlename: Maybe<Scalars['String']['output']>;
  telegram_id: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "me". All fields are combined with a logical 'AND'. */
export type MeBoolExp = {
  _and: InputMaybe<Array<MeBoolExp>>;
  _not: InputMaybe<MeBoolExp>;
  _or: InputMaybe<Array<MeBoolExp>>;
  balance: InputMaybe<NumericComparisonExp>;
  balance_at: InputMaybe<TimestamptzComparisonExp>;
  birth_date: InputMaybe<DateComparisonExp>;
  entered_at: InputMaybe<DateComparisonExp>;
  firstname: InputMaybe<StringComparisonExp>;
  full_name: InputMaybe<StringComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  last_paid_amount: InputMaybe<NumericComparisonExp>;
  last_paid_at: InputMaybe<DateComparisonExp>;
  lastname: InputMaybe<StringComparisonExp>;
  middlename: InputMaybe<StringComparisonExp>;
  telegram_id: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "me". */
export type MeOrderBy = {
  balance: InputMaybe<OrderBy>;
  balance_at: InputMaybe<OrderBy>;
  birth_date: InputMaybe<OrderBy>;
  entered_at: InputMaybe<OrderBy>;
  firstname: InputMaybe<OrderBy>;
  full_name: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  last_paid_amount: InputMaybe<OrderBy>;
  last_paid_at: InputMaybe<OrderBy>;
  lastname: InputMaybe<OrderBy>;
  middlename: InputMaybe<OrderBy>;
  telegram_id: InputMaybe<OrderBy>;
};

/** select columns of table "me" */
export enum MeSelectColumn {
  /** column name */
  Balance = 'balance',
  /** column name */
  BalanceAt = 'balance_at',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  EnteredAt = 'entered_at',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastPaidAmount = 'last_paid_amount',
  /** column name */
  LastPaidAt = 'last_paid_at',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  Middlename = 'middlename',
  /** column name */
  TelegramId = 'telegram_id'
}

/** Streaming cursor of the table "me" */
export type MeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MeStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MeStreamCursorValueInput = {
  balance: InputMaybe<Scalars['numeric']['input']>;
  balance_at: InputMaybe<Scalars['timestamptz']['input']>;
  birth_date: InputMaybe<Scalars['date']['input']>;
  entered_at: InputMaybe<Scalars['date']['input']>;
  firstname: InputMaybe<Scalars['String']['input']>;
  full_name: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  last_paid_amount: InputMaybe<Scalars['numeric']['input']>;
  last_paid_at: InputMaybe<Scalars['date']['input']>;
  lastname: InputMaybe<Scalars['String']['input']>;
  middlename: InputMaybe<Scalars['String']['input']>;
  telegram_id: InputMaybe<Scalars['String']['input']>;
};

/** Членские взносы */
export type MemberPayment = {
  __typename?: 'member_payment';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid']['output'];
  amount: Scalars['numeric']['output'];
  balance: Maybe<Scalars['numeric']['output']>;
  id: Scalars['uuid']['output'];
  is_discount: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  land: Maybe<Land>;
  land_id: Maybe<Scalars['uuid']['output']>;
  paid_at: Scalars['date']['output'];
  person_id: Maybe<Scalars['uuid']['output']>;
  rate: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "member_payment" */
export type MemberPaymentAggregate = {
  __typename?: 'member_payment_aggregate';
  aggregate: Maybe<MemberPaymentAggregateFields>;
  nodes: Array<MemberPayment>;
};

export type MemberPaymentAggregateBoolExp = {
  bool_and: InputMaybe<MemberPaymentAggregateBoolExpBoolAnd>;
  bool_or: InputMaybe<MemberPaymentAggregateBoolExpBoolOr>;
  count: InputMaybe<MemberPaymentAggregateBoolExpCount>;
};

export type MemberPaymentAggregateBoolExpBoolAnd = {
  arguments: MemberPaymentSelectColumnMemberPaymentAggregateBoolExpBoolAndArgumentsColumns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MemberPaymentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MemberPaymentAggregateBoolExpBoolOr = {
  arguments: MemberPaymentSelectColumnMemberPaymentAggregateBoolExpBoolOrArgumentsColumns;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MemberPaymentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MemberPaymentAggregateBoolExpCount = {
  arguments: InputMaybe<Array<MemberPaymentSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<MemberPaymentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "member_payment" */
export type MemberPaymentAggregateFields = {
  __typename?: 'member_payment_aggregate_fields';
  avg: Maybe<MemberPaymentAvgFields>;
  count: Scalars['Int']['output'];
  max: Maybe<MemberPaymentMaxFields>;
  min: Maybe<MemberPaymentMinFields>;
  stddev: Maybe<MemberPaymentStddevFields>;
  stddev_pop: Maybe<MemberPaymentStddevPopFields>;
  stddev_samp: Maybe<MemberPaymentStddevSampFields>;
  sum: Maybe<MemberPaymentSumFields>;
  var_pop: Maybe<MemberPaymentVarPopFields>;
  var_samp: Maybe<MemberPaymentVarSampFields>;
  variance: Maybe<MemberPaymentVarianceFields>;
};


/** aggregate fields of "member_payment" */
export type MemberPaymentAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<MemberPaymentSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "member_payment" */
export type MemberPaymentAggregateOrderBy = {
  avg: InputMaybe<MemberPaymentAvgOrderBy>;
  count: InputMaybe<OrderBy>;
  max: InputMaybe<MemberPaymentMaxOrderBy>;
  min: InputMaybe<MemberPaymentMinOrderBy>;
  stddev: InputMaybe<MemberPaymentStddevOrderBy>;
  stddev_pop: InputMaybe<MemberPaymentStddevPopOrderBy>;
  stddev_samp: InputMaybe<MemberPaymentStddevSampOrderBy>;
  sum: InputMaybe<MemberPaymentSumOrderBy>;
  var_pop: InputMaybe<MemberPaymentVarPopOrderBy>;
  var_samp: InputMaybe<MemberPaymentVarSampOrderBy>;
  variance: InputMaybe<MemberPaymentVarianceOrderBy>;
};

/** aggregate avg on columns */
export type MemberPaymentAvgFields = {
  __typename?: 'member_payment_avg_fields';
  amount: Maybe<Scalars['Float']['output']>;
  balance: Maybe<Scalars['Float']['output']>;
  rate: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "member_payment" */
export type MemberPaymentAvgOrderBy = {
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "member_payment". All fields are combined with a logical 'AND'. */
export type MemberPaymentBoolExp = {
  _and: InputMaybe<Array<MemberPaymentBoolExp>>;
  _not: InputMaybe<MemberPaymentBoolExp>;
  _or: InputMaybe<Array<MemberPaymentBoolExp>>;
  account: InputMaybe<AccountBoolExp>;
  account_id: InputMaybe<UuidComparisonExp>;
  amount: InputMaybe<NumericComparisonExp>;
  balance: InputMaybe<NumericComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  is_discount: InputMaybe<BooleanComparisonExp>;
  land: InputMaybe<LandBoolExp>;
  land_id: InputMaybe<UuidComparisonExp>;
  paid_at: InputMaybe<DateComparisonExp>;
  person_id: InputMaybe<UuidComparisonExp>;
  rate: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type MemberPaymentMaxFields = {
  __typename?: 'member_payment_max_fields';
  account_id: Maybe<Scalars['uuid']['output']>;
  amount: Maybe<Scalars['numeric']['output']>;
  balance: Maybe<Scalars['numeric']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  land_id: Maybe<Scalars['uuid']['output']>;
  paid_at: Maybe<Scalars['date']['output']>;
  person_id: Maybe<Scalars['uuid']['output']>;
  rate: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "member_payment" */
export type MemberPaymentMaxOrderBy = {
  account_id: InputMaybe<OrderBy>;
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  land_id: InputMaybe<OrderBy>;
  paid_at: InputMaybe<OrderBy>;
  person_id: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MemberPaymentMinFields = {
  __typename?: 'member_payment_min_fields';
  account_id: Maybe<Scalars['uuid']['output']>;
  amount: Maybe<Scalars['numeric']['output']>;
  balance: Maybe<Scalars['numeric']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  land_id: Maybe<Scalars['uuid']['output']>;
  paid_at: Maybe<Scalars['date']['output']>;
  person_id: Maybe<Scalars['uuid']['output']>;
  rate: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "member_payment" */
export type MemberPaymentMinOrderBy = {
  account_id: InputMaybe<OrderBy>;
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  land_id: InputMaybe<OrderBy>;
  paid_at: InputMaybe<OrderBy>;
  person_id: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "member_payment". */
export type MemberPaymentOrderBy = {
  account: InputMaybe<AccountOrderBy>;
  account_id: InputMaybe<OrderBy>;
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  is_discount: InputMaybe<OrderBy>;
  land: InputMaybe<LandOrderBy>;
  land_id: InputMaybe<OrderBy>;
  paid_at: InputMaybe<OrderBy>;
  person_id: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** select columns of table "member_payment" */
export enum MemberPaymentSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  Balance = 'balance',
  /** column name */
  Id = 'id',
  /** column name */
  IsDiscount = 'is_discount',
  /** column name */
  LandId = 'land_id',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  Rate = 'rate'
}

/** select "member_payment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "member_payment" */
export enum MemberPaymentSelectColumnMemberPaymentAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDiscount = 'is_discount'
}

/** select "member_payment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "member_payment" */
export enum MemberPaymentSelectColumnMemberPaymentAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDiscount = 'is_discount'
}

/** aggregate stddev on columns */
export type MemberPaymentStddevFields = {
  __typename?: 'member_payment_stddev_fields';
  amount: Maybe<Scalars['Float']['output']>;
  balance: Maybe<Scalars['Float']['output']>;
  rate: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "member_payment" */
export type MemberPaymentStddevOrderBy = {
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MemberPaymentStddevPopFields = {
  __typename?: 'member_payment_stddev_pop_fields';
  amount: Maybe<Scalars['Float']['output']>;
  balance: Maybe<Scalars['Float']['output']>;
  rate: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "member_payment" */
export type MemberPaymentStddevPopOrderBy = {
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MemberPaymentStddevSampFields = {
  __typename?: 'member_payment_stddev_samp_fields';
  amount: Maybe<Scalars['Float']['output']>;
  balance: Maybe<Scalars['Float']['output']>;
  rate: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "member_payment" */
export type MemberPaymentStddevSampOrderBy = {
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "member_payment" */
export type MemberPaymentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MemberPaymentStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MemberPaymentStreamCursorValueInput = {
  account_id: InputMaybe<Scalars['uuid']['input']>;
  amount: InputMaybe<Scalars['numeric']['input']>;
  balance: InputMaybe<Scalars['numeric']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  is_discount: InputMaybe<Scalars['Boolean']['input']>;
  land_id: InputMaybe<Scalars['uuid']['input']>;
  paid_at: InputMaybe<Scalars['date']['input']>;
  person_id: InputMaybe<Scalars['uuid']['input']>;
  rate: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type MemberPaymentSumFields = {
  __typename?: 'member_payment_sum_fields';
  amount: Maybe<Scalars['numeric']['output']>;
  balance: Maybe<Scalars['numeric']['output']>;
  rate: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "member_payment" */
export type MemberPaymentSumOrderBy = {
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type MemberPaymentVarPopFields = {
  __typename?: 'member_payment_var_pop_fields';
  amount: Maybe<Scalars['Float']['output']>;
  balance: Maybe<Scalars['Float']['output']>;
  rate: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "member_payment" */
export type MemberPaymentVarPopOrderBy = {
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MemberPaymentVarSampFields = {
  __typename?: 'member_payment_var_samp_fields';
  amount: Maybe<Scalars['Float']['output']>;
  balance: Maybe<Scalars['Float']['output']>;
  rate: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "member_payment" */
export type MemberPaymentVarSampOrderBy = {
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MemberPaymentVarianceFields = {
  __typename?: 'member_payment_variance_fields';
  amount: Maybe<Scalars['Float']['output']>;
  balance: Maybe<Scalars['Float']['output']>;
  rate: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "member_payment" */
export type MemberPaymentVarianceOrderBy = {
  amount: InputMaybe<OrderBy>;
  balance: InputMaybe<OrderBy>;
  rate: InputMaybe<OrderBy>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** execute VOLATILE function "open_gate_site" which returns "gate_open" */
  open_gate_site: Array<GateOpen>;
};


/** mutation root */
export type MutationRootOpenGateSiteArgs = {
  args: OpenGateSiteArgs;
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq: InputMaybe<Scalars['numeric']['input']>;
  _gt: InputMaybe<Scalars['numeric']['input']>;
  _gte: InputMaybe<Scalars['numeric']['input']>;
  _in: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['numeric']['input']>;
  _lte: InputMaybe<Scalars['numeric']['input']>;
  _neq: InputMaybe<Scalars['numeric']['input']>;
  _nin: InputMaybe<Array<Scalars['numeric']['input']>>;
};

export type OpenGateSiteArgs = {
  gate: InputMaybe<Scalars['String']['input']>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Члены СНТ */
export type Person = {
  __typename?: 'person';
  /** An array relationship */
  accounts: Array<Account>;
  /** An aggregate relationship */
  accounts_aggregate: AccountAggregate;
  full_name: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Maybe<User>;
};


/** Члены СНТ */
export type PersonAccountsArgs = {
  distinct_on: InputMaybe<Array<AccountSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<AccountOrderBy>>;
  where: InputMaybe<AccountBoolExp>;
};


/** Члены СНТ */
export type PersonAccountsAggregateArgs = {
  distinct_on: InputMaybe<Array<AccountSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<AccountOrderBy>>;
  where: InputMaybe<AccountBoolExp>;
};

/** aggregated selection of "person" */
export type PersonAggregate = {
  __typename?: 'person_aggregate';
  aggregate: Maybe<PersonAggregateFields>;
  nodes: Array<Person>;
};

export type PersonAggregateBoolExp = {
  count: InputMaybe<PersonAggregateBoolExpCount>;
};

export type PersonAggregateBoolExpCount = {
  arguments: InputMaybe<Array<PersonSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
  filter: InputMaybe<PersonBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "person" */
export type PersonAggregateFields = {
  __typename?: 'person_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<PersonMaxFields>;
  min: Maybe<PersonMinFields>;
};


/** aggregate fields of "person" */
export type PersonAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<PersonSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "person" */
export type PersonAggregateOrderBy = {
  count: InputMaybe<OrderBy>;
  max: InputMaybe<PersonMaxOrderBy>;
  min: InputMaybe<PersonMinOrderBy>;
};

/** Boolean expression to filter rows from the table "person". All fields are combined with a logical 'AND'. */
export type PersonBoolExp = {
  _and: InputMaybe<Array<PersonBoolExp>>;
  _not: InputMaybe<PersonBoolExp>;
  _or: InputMaybe<Array<PersonBoolExp>>;
  accounts: InputMaybe<AccountBoolExp>;
  accounts_aggregate: InputMaybe<AccountAggregateBoolExp>;
  full_name: InputMaybe<StringComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  user: InputMaybe<UserBoolExp>;
};

/** aggregate max on columns */
export type PersonMaxFields = {
  __typename?: 'person_max_fields';
  full_name: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "person" */
export type PersonMaxOrderBy = {
  full_name: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PersonMinFields = {
  __typename?: 'person_min_fields';
  full_name: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "person" */
export type PersonMinOrderBy = {
  full_name: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "person". */
export type PersonOrderBy = {
  accounts_aggregate: InputMaybe<AccountAggregateOrderBy>;
  full_name: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  user: InputMaybe<UserOrderBy>;
};

/** select columns of table "person" */
export enum PersonSelectColumn {
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "person" */
export type PersonStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PersonStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PersonStreamCursorValueInput = {
  full_name: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to compare columns of type "point". All fields are combined with logical 'AND'. */
export type PointComparisonExp = {
  _eq: InputMaybe<Scalars['point']['input']>;
  _gt: InputMaybe<Scalars['point']['input']>;
  _gte: InputMaybe<Scalars['point']['input']>;
  _in: InputMaybe<Array<Scalars['point']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['point']['input']>;
  _lte: InputMaybe<Scalars['point']['input']>;
  _neq: InputMaybe<Scalars['point']['input']>;
  _nin: InputMaybe<Array<Scalars['point']['input']>>;
};

/** Boolean expression to compare columns of type "polygon". All fields are combined with logical 'AND'. */
export type PolygonComparisonExp = {
  _eq: InputMaybe<Scalars['polygon']['input']>;
  _gt: InputMaybe<Scalars['polygon']['input']>;
  _gte: InputMaybe<Scalars['polygon']['input']>;
  _in: InputMaybe<Array<Scalars['polygon']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['polygon']['input']>;
  _lte: InputMaybe<Scalars['polygon']['input']>;
  _neq: InputMaybe<Scalars['polygon']['input']>;
  _nin: InputMaybe<Array<Scalars['polygon']['input']>>;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: AccountAggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk: Maybe<Account>;
  /** fetch data from the table: "cctv" */
  cctv: Array<Cctv>;
  /** fetch aggregated fields from the table: "cctv" */
  cctv_aggregate: CctvAggregate;
  /** fetch data from the table: "cctv" using primary key columns */
  cctv_by_pk: Maybe<Cctv>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: ContactAggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk: Maybe<Contact>;
  /** fetch data from the table: "gate" */
  gate: Array<Gate>;
  /** fetch aggregated fields from the table: "gate" */
  gate_aggregate: GateAggregate;
  /** fetch data from the table: "gate" using primary key columns */
  gate_by_pk: Maybe<Gate>;
  /** fetch data from the table: "gate_open" */
  gate_open: Array<GateOpen>;
  /** fetch aggregated fields from the table: "gate_open" */
  gate_open_aggregate: GateOpenAggregate;
  /** fetch data from the table: "gate_open" using primary key columns */
  gate_open_by_pk: Maybe<GateOpen>;
  /** fetch data from the table: "gate_open_reason" */
  gate_open_reason: Array<GateOpenReason>;
  /** fetch aggregated fields from the table: "gate_open_reason" */
  gate_open_reason_aggregate: GateOpenReasonAggregate;
  /** fetch data from the table: "gate_open_reason" using primary key columns */
  gate_open_reason_by_pk: Maybe<GateOpenReason>;
  /** fetch data from the table: "land" */
  land: Array<Land>;
  /** fetch aggregated fields from the table: "land" */
  land_aggregate: LandAggregate;
  /** fetch data from the table: "land" using primary key columns */
  land_by_pk: Maybe<Land>;
  /** fetch data from the table: "me" */
  me: Array<Me>;
  /** fetch data from the table: "member_payment" */
  member_payment: Array<MemberPayment>;
  /** fetch aggregated fields from the table: "member_payment" */
  member_payment_aggregate: MemberPaymentAggregate;
  /** fetch data from the table: "member_payment" using primary key columns */
  member_payment_by_pk: Maybe<MemberPayment>;
  /** An array relationship */
  person: Array<Person>;
  /** An aggregate relationship */
  person_aggregate: PersonAggregate;
  /** fetch data from the table: "person" using primary key columns */
  person_by_pk: Maybe<Person>;
  /** fetch data from the table: "street" */
  street: Array<Street>;
  /** fetch aggregated fields from the table: "street" */
  street_aggregate: StreetAggregate;
  /** fetch data from the table: "street" using primary key columns */
  street_by_pk: Maybe<Street>;
  /** fetch data from the table: "users" */
  user: Array<User>;
  /** fetch data from the table: "users" using primary key columns */
  user_by_pk: Maybe<User>;
};


export type QueryRootAccountArgs = {
  distinct_on: InputMaybe<Array<AccountSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<AccountOrderBy>>;
  where: InputMaybe<AccountBoolExp>;
};


export type QueryRootAccountAggregateArgs = {
  distinct_on: InputMaybe<Array<AccountSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<AccountOrderBy>>;
  where: InputMaybe<AccountBoolExp>;
};


export type QueryRootAccountByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootCctvArgs = {
  distinct_on: InputMaybe<Array<CctvSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<CctvOrderBy>>;
  where: InputMaybe<CctvBoolExp>;
};


export type QueryRootCctvAggregateArgs = {
  distinct_on: InputMaybe<Array<CctvSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<CctvOrderBy>>;
  where: InputMaybe<CctvBoolExp>;
};


export type QueryRootCctvByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootContactArgs = {
  distinct_on: InputMaybe<Array<ContactSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<ContactOrderBy>>;
  where: InputMaybe<ContactBoolExp>;
};


export type QueryRootContactAggregateArgs = {
  distinct_on: InputMaybe<Array<ContactSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<ContactOrderBy>>;
  where: InputMaybe<ContactBoolExp>;
};


export type QueryRootContactByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootGateArgs = {
  distinct_on: InputMaybe<Array<GateSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOrderBy>>;
  where: InputMaybe<GateBoolExp>;
};


export type QueryRootGateAggregateArgs = {
  distinct_on: InputMaybe<Array<GateSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOrderBy>>;
  where: InputMaybe<GateBoolExp>;
};


export type QueryRootGateByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootGateOpenArgs = {
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};


export type QueryRootGateOpenAggregateArgs = {
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};


export type QueryRootGateOpenByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootGateOpenReasonArgs = {
  distinct_on: InputMaybe<Array<GateOpenReasonSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenReasonOrderBy>>;
  where: InputMaybe<GateOpenReasonBoolExp>;
};


export type QueryRootGateOpenReasonAggregateArgs = {
  distinct_on: InputMaybe<Array<GateOpenReasonSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenReasonOrderBy>>;
  where: InputMaybe<GateOpenReasonBoolExp>;
};


export type QueryRootGateOpenReasonByPkArgs = {
  id: Scalars['String']['input'];
};


export type QueryRootLandArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};


export type QueryRootLandAggregateArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};


export type QueryRootLandByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootMeArgs = {
  distinct_on: InputMaybe<Array<MeSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MeOrderBy>>;
  where: InputMaybe<MeBoolExp>;
};


export type QueryRootMemberPaymentArgs = {
  distinct_on: InputMaybe<Array<MemberPaymentSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MemberPaymentOrderBy>>;
  where: InputMaybe<MemberPaymentBoolExp>;
};


export type QueryRootMemberPaymentAggregateArgs = {
  distinct_on: InputMaybe<Array<MemberPaymentSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MemberPaymentOrderBy>>;
  where: InputMaybe<MemberPaymentBoolExp>;
};


export type QueryRootMemberPaymentByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootPersonArgs = {
  distinct_on: InputMaybe<Array<PersonSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<PersonOrderBy>>;
  where: InputMaybe<PersonBoolExp>;
};


export type QueryRootPersonAggregateArgs = {
  distinct_on: InputMaybe<Array<PersonSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<PersonOrderBy>>;
  where: InputMaybe<PersonBoolExp>;
};


export type QueryRootPersonByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootStreetArgs = {
  distinct_on: InputMaybe<Array<StreetSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<StreetOrderBy>>;
  where: InputMaybe<StreetBoolExp>;
};


export type QueryRootStreetAggregateArgs = {
  distinct_on: InputMaybe<Array<StreetSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<StreetOrderBy>>;
  where: InputMaybe<StreetBoolExp>;
};


export type QueryRootStreetByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootUserArgs = {
  distinct_on: InputMaybe<Array<UserSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<UserOrderBy>>;
  where: InputMaybe<UserBoolExp>;
};


export type QueryRootUserByPkArgs = {
  id: Scalars['uuid']['input'];
};

/** Улицы */
export type Street = {
  __typename?: 'street';
  created_at: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  lands: Array<Land>;
  /** An aggregate relationship */
  lands_aggregate: LandAggregate;
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** Улицы */
export type StreetLandsArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};


/** Улицы */
export type StreetLandsAggregateArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};

/** aggregated selection of "street" */
export type StreetAggregate = {
  __typename?: 'street_aggregate';
  aggregate: Maybe<StreetAggregateFields>;
  nodes: Array<Street>;
};

/** aggregate fields of "street" */
export type StreetAggregateFields = {
  __typename?: 'street_aggregate_fields';
  count: Scalars['Int']['output'];
  max: Maybe<StreetMaxFields>;
  min: Maybe<StreetMinFields>;
};


/** aggregate fields of "street" */
export type StreetAggregateFieldsCountArgs = {
  columns: InputMaybe<Array<StreetSelectColumn>>;
  distinct: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "street". All fields are combined with a logical 'AND'. */
export type StreetBoolExp = {
  _and: InputMaybe<Array<StreetBoolExp>>;
  _not: InputMaybe<StreetBoolExp>;
  _or: InputMaybe<Array<StreetBoolExp>>;
  created_at: InputMaybe<TimestamptzComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  lands: InputMaybe<LandBoolExp>;
  lands_aggregate: InputMaybe<LandAggregateBoolExp>;
  name: InputMaybe<StringComparisonExp>;
  updated_at: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type StreetMaxFields = {
  __typename?: 'street_max_fields';
  created_at: Maybe<Scalars['timestamptz']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type StreetMinFields = {
  __typename?: 'street_min_fields';
  created_at: Maybe<Scalars['timestamptz']['output']>;
  id: Maybe<Scalars['uuid']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updated_at: Maybe<Scalars['timestamptz']['output']>;
};

/** Ordering options when selecting data from "street". */
export type StreetOrderBy = {
  created_at: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  lands_aggregate: InputMaybe<LandAggregateOrderBy>;
  name: InputMaybe<OrderBy>;
  updated_at: InputMaybe<OrderBy>;
};

/** select columns of table "street" */
export enum StreetSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "street" */
export type StreetStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: StreetStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StreetStreamCursorValueInput = {
  created_at: InputMaybe<Scalars['timestamptz']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  updated_at: InputMaybe<Scalars['timestamptz']['input']>;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: AccountAggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk: Maybe<Account>;
  /** fetch data from the table in a streaming manner: "account" */
  account_stream: Array<Account>;
  /** fetch data from the table: "cctv" */
  cctv: Array<Cctv>;
  /** fetch aggregated fields from the table: "cctv" */
  cctv_aggregate: CctvAggregate;
  /** fetch data from the table: "cctv" using primary key columns */
  cctv_by_pk: Maybe<Cctv>;
  /** fetch data from the table in a streaming manner: "cctv" */
  cctv_stream: Array<Cctv>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: ContactAggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk: Maybe<Contact>;
  /** fetch data from the table in a streaming manner: "contact" */
  contact_stream: Array<Contact>;
  /** fetch data from the table: "gate" */
  gate: Array<Gate>;
  /** fetch aggregated fields from the table: "gate" */
  gate_aggregate: GateAggregate;
  /** fetch data from the table: "gate" using primary key columns */
  gate_by_pk: Maybe<Gate>;
  /** fetch data from the table: "gate_open" */
  gate_open: Array<GateOpen>;
  /** fetch aggregated fields from the table: "gate_open" */
  gate_open_aggregate: GateOpenAggregate;
  /** fetch data from the table: "gate_open" using primary key columns */
  gate_open_by_pk: Maybe<GateOpen>;
  /** fetch data from the table: "gate_open_reason" */
  gate_open_reason: Array<GateOpenReason>;
  /** fetch aggregated fields from the table: "gate_open_reason" */
  gate_open_reason_aggregate: GateOpenReasonAggregate;
  /** fetch data from the table: "gate_open_reason" using primary key columns */
  gate_open_reason_by_pk: Maybe<GateOpenReason>;
  /** fetch data from the table in a streaming manner: "gate_open_reason" */
  gate_open_reason_stream: Array<GateOpenReason>;
  /** fetch data from the table in a streaming manner: "gate_open" */
  gate_open_stream: Array<GateOpen>;
  /** fetch data from the table in a streaming manner: "gate" */
  gate_stream: Array<Gate>;
  /** fetch data from the table: "land" */
  land: Array<Land>;
  /** fetch aggregated fields from the table: "land" */
  land_aggregate: LandAggregate;
  /** fetch data from the table: "land" using primary key columns */
  land_by_pk: Maybe<Land>;
  /** fetch data from the table in a streaming manner: "land" */
  land_stream: Array<Land>;
  /** fetch data from the table: "me" */
  me: Array<Me>;
  /** fetch data from the table in a streaming manner: "me" */
  me_stream: Array<Me>;
  /** fetch data from the table: "member_payment" */
  member_payment: Array<MemberPayment>;
  /** fetch aggregated fields from the table: "member_payment" */
  member_payment_aggregate: MemberPaymentAggregate;
  /** fetch data from the table: "member_payment" using primary key columns */
  member_payment_by_pk: Maybe<MemberPayment>;
  /** fetch data from the table in a streaming manner: "member_payment" */
  member_payment_stream: Array<MemberPayment>;
  /** An array relationship */
  person: Array<Person>;
  /** An aggregate relationship */
  person_aggregate: PersonAggregate;
  /** fetch data from the table: "person" using primary key columns */
  person_by_pk: Maybe<Person>;
  /** fetch data from the table in a streaming manner: "person" */
  person_stream: Array<Person>;
  /** fetch data from the table: "street" */
  street: Array<Street>;
  /** fetch aggregated fields from the table: "street" */
  street_aggregate: StreetAggregate;
  /** fetch data from the table: "street" using primary key columns */
  street_by_pk: Maybe<Street>;
  /** fetch data from the table in a streaming manner: "street" */
  street_stream: Array<Street>;
  /** fetch data from the table: "users" */
  user: Array<User>;
  /** fetch data from the table: "users" using primary key columns */
  user_by_pk: Maybe<User>;
  /** fetch data from the table in a streaming manner: "users" */
  user_stream: Array<User>;
};


export type SubscriptionRootAccountArgs = {
  distinct_on: InputMaybe<Array<AccountSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<AccountOrderBy>>;
  where: InputMaybe<AccountBoolExp>;
};


export type SubscriptionRootAccountAggregateArgs = {
  distinct_on: InputMaybe<Array<AccountSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<AccountOrderBy>>;
  where: InputMaybe<AccountBoolExp>;
};


export type SubscriptionRootAccountByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootAccountStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccountStreamCursorInput>>;
  where: InputMaybe<AccountBoolExp>;
};


export type SubscriptionRootCctvArgs = {
  distinct_on: InputMaybe<Array<CctvSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<CctvOrderBy>>;
  where: InputMaybe<CctvBoolExp>;
};


export type SubscriptionRootCctvAggregateArgs = {
  distinct_on: InputMaybe<Array<CctvSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<CctvOrderBy>>;
  where: InputMaybe<CctvBoolExp>;
};


export type SubscriptionRootCctvByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootCctvStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CctvStreamCursorInput>>;
  where: InputMaybe<CctvBoolExp>;
};


export type SubscriptionRootContactArgs = {
  distinct_on: InputMaybe<Array<ContactSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<ContactOrderBy>>;
  where: InputMaybe<ContactBoolExp>;
};


export type SubscriptionRootContactAggregateArgs = {
  distinct_on: InputMaybe<Array<ContactSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<ContactOrderBy>>;
  where: InputMaybe<ContactBoolExp>;
};


export type SubscriptionRootContactByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootContactStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContactStreamCursorInput>>;
  where: InputMaybe<ContactBoolExp>;
};


export type SubscriptionRootGateArgs = {
  distinct_on: InputMaybe<Array<GateSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOrderBy>>;
  where: InputMaybe<GateBoolExp>;
};


export type SubscriptionRootGateAggregateArgs = {
  distinct_on: InputMaybe<Array<GateSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOrderBy>>;
  where: InputMaybe<GateBoolExp>;
};


export type SubscriptionRootGateByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootGateOpenArgs = {
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};


export type SubscriptionRootGateOpenAggregateArgs = {
  distinct_on: InputMaybe<Array<GateOpenSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenOrderBy>>;
  where: InputMaybe<GateOpenBoolExp>;
};


export type SubscriptionRootGateOpenByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootGateOpenReasonArgs = {
  distinct_on: InputMaybe<Array<GateOpenReasonSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenReasonOrderBy>>;
  where: InputMaybe<GateOpenReasonBoolExp>;
};


export type SubscriptionRootGateOpenReasonAggregateArgs = {
  distinct_on: InputMaybe<Array<GateOpenReasonSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<GateOpenReasonOrderBy>>;
  where: InputMaybe<GateOpenReasonBoolExp>;
};


export type SubscriptionRootGateOpenReasonByPkArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionRootGateOpenReasonStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GateOpenReasonStreamCursorInput>>;
  where: InputMaybe<GateOpenReasonBoolExp>;
};


export type SubscriptionRootGateOpenStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GateOpenStreamCursorInput>>;
  where: InputMaybe<GateOpenBoolExp>;
};


export type SubscriptionRootGateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GateStreamCursorInput>>;
  where: InputMaybe<GateBoolExp>;
};


export type SubscriptionRootLandArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};


export type SubscriptionRootLandAggregateArgs = {
  distinct_on: InputMaybe<Array<LandSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<LandOrderBy>>;
  where: InputMaybe<LandBoolExp>;
};


export type SubscriptionRootLandByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootLandStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LandStreamCursorInput>>;
  where: InputMaybe<LandBoolExp>;
};


export type SubscriptionRootMeArgs = {
  distinct_on: InputMaybe<Array<MeSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MeOrderBy>>;
  where: InputMaybe<MeBoolExp>;
};


export type SubscriptionRootMeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MeStreamCursorInput>>;
  where: InputMaybe<MeBoolExp>;
};


export type SubscriptionRootMemberPaymentArgs = {
  distinct_on: InputMaybe<Array<MemberPaymentSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MemberPaymentOrderBy>>;
  where: InputMaybe<MemberPaymentBoolExp>;
};


export type SubscriptionRootMemberPaymentAggregateArgs = {
  distinct_on: InputMaybe<Array<MemberPaymentSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<MemberPaymentOrderBy>>;
  where: InputMaybe<MemberPaymentBoolExp>;
};


export type SubscriptionRootMemberPaymentByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootMemberPaymentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MemberPaymentStreamCursorInput>>;
  where: InputMaybe<MemberPaymentBoolExp>;
};


export type SubscriptionRootPersonArgs = {
  distinct_on: InputMaybe<Array<PersonSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<PersonOrderBy>>;
  where: InputMaybe<PersonBoolExp>;
};


export type SubscriptionRootPersonAggregateArgs = {
  distinct_on: InputMaybe<Array<PersonSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<PersonOrderBy>>;
  where: InputMaybe<PersonBoolExp>;
};


export type SubscriptionRootPersonByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootPersonStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PersonStreamCursorInput>>;
  where: InputMaybe<PersonBoolExp>;
};


export type SubscriptionRootStreetArgs = {
  distinct_on: InputMaybe<Array<StreetSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<StreetOrderBy>>;
  where: InputMaybe<StreetBoolExp>;
};


export type SubscriptionRootStreetAggregateArgs = {
  distinct_on: InputMaybe<Array<StreetSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<StreetOrderBy>>;
  where: InputMaybe<StreetBoolExp>;
};


export type SubscriptionRootStreetByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootStreetStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StreetStreamCursorInput>>;
  where: InputMaybe<StreetBoolExp>;
};


export type SubscriptionRootUserArgs = {
  distinct_on: InputMaybe<Array<UserSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<UserOrderBy>>;
  where: InputMaybe<UserBoolExp>;
};


export type SubscriptionRootUserByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootUserStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserStreamCursorInput>>;
  where: InputMaybe<UserBoolExp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq: InputMaybe<Scalars['timestamptz']['input']>;
  _gt: InputMaybe<Scalars['timestamptz']['input']>;
  _gte: InputMaybe<Scalars['timestamptz']['input']>;
  _in: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['timestamptz']['input']>;
  _lte: InputMaybe<Scalars['timestamptz']['input']>;
  _neq: InputMaybe<Scalars['timestamptz']['input']>;
  _nin: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "users" */
export type User = {
  __typename?: 'user';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  person: Array<Person>;
  /** An aggregate relationship */
  person_aggregate: PersonAggregate;
  updated_at: Scalars['timestamptz']['output'];
  username: Scalars['String']['output'];
};


/** columns and relationships of "users" */
export type UserPersonArgs = {
  distinct_on: InputMaybe<Array<PersonSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<PersonOrderBy>>;
  where: InputMaybe<PersonBoolExp>;
};


/** columns and relationships of "users" */
export type UserPersonAggregateArgs = {
  distinct_on: InputMaybe<Array<PersonSelectColumn>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  order_by: InputMaybe<Array<PersonOrderBy>>;
  where: InputMaybe<PersonBoolExp>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  _and: InputMaybe<Array<UserBoolExp>>;
  _not: InputMaybe<UserBoolExp>;
  _or: InputMaybe<Array<UserBoolExp>>;
  created_at: InputMaybe<TimestamptzComparisonExp>;
  id: InputMaybe<UuidComparisonExp>;
  person: InputMaybe<PersonBoolExp>;
  person_aggregate: InputMaybe<PersonAggregateBoolExp>;
  updated_at: InputMaybe<TimestamptzComparisonExp>;
  username: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "users". */
export type UserOrderBy = {
  created_at: InputMaybe<OrderBy>;
  id: InputMaybe<OrderBy>;
  person_aggregate: InputMaybe<PersonAggregateOrderBy>;
  updated_at: InputMaybe<OrderBy>;
  username: InputMaybe<OrderBy>;
};

/** select columns of table "users" */
export enum UserSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** Streaming cursor of the table "user" */
export type UserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserStreamCursorValueInput;
  /** cursor ordering */
  ordering: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserStreamCursorValueInput = {
  created_at: InputMaybe<Scalars['timestamptz']['input']>;
  id: InputMaybe<Scalars['uuid']['input']>;
  updated_at: InputMaybe<Scalars['timestamptz']['input']>;
  username: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq: InputMaybe<Scalars['uuid']['input']>;
  _gt: InputMaybe<Scalars['uuid']['input']>;
  _gte: InputMaybe<Scalars['uuid']['input']>;
  _in: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null: InputMaybe<Scalars['Boolean']['input']>;
  _lt: InputMaybe<Scalars['uuid']['input']>;
  _lte: InputMaybe<Scalars['uuid']['input']>;
  _neq: InputMaybe<Scalars['uuid']['input']>;
  _nin: InputMaybe<Array<Scalars['uuid']['input']>>;
};

import { dateTypePolicy } from "../components/dayjs";

export const scalarTypePolicies = {
  account: { fields: { balance_at: dateTypePolicy } },
  account_max_fields: { fields: { balance_at: dateTypePolicy } },
  account_min_fields: { fields: { balance_at: dateTypePolicy } },
  cctv: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  cctv_max_fields: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  cctv_min_fields: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  gate: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  gate_max_fields: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  gate_min_fields: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  gate_open: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  gate_open_max_fields: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  gate_open_min_fields: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  gate_open_reason: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  gate_open_reason_max_fields: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  gate_open_reason_min_fields: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  me: { fields: { balance_at: dateTypePolicy } },
  street: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  street_max_fields: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  street_min_fields: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
  user: { fields: { created_at: dateTypePolicy, updated_at: dateTypePolicy } },
};

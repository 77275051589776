import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LandsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LandsQuery = { __typename?: 'query_root', gate: Array<{ __typename?: 'gate', id: string, name: string, number: number | null, coordinates: any | null }>, land: Array<{ __typename?: 'land', id: string, number: string | null, coordinates: any | null, street: { __typename?: 'street', name: string } }> };


export const LandsDocument = gql`
    query lands {
  gate(where: {coordinates: {_is_null: false}}, order_by: {number: asc}) {
    id
    name
    number
    coordinates
  }
  land(where: {coordinates: {_is_null: false}}, order_by: {number_integer: asc}) {
    id
    number
    street {
      name
    }
    coordinates
  }
}
    `;

/**
 * __useLandsQuery__
 *
 * To run a query within a React component, call `useLandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLandsQuery(baseOptions?: Apollo.QueryHookOptions<LandsQuery, LandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LandsQuery, LandsQueryVariables>(LandsDocument, options);
      }
export function useLandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LandsQuery, LandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LandsQuery, LandsQueryVariables>(LandsDocument, options);
        }
export type LandsQueryHookResult = ReturnType<typeof useLandsQuery>;
export type LandsLazyQueryHookResult = ReturnType<typeof useLandsLazyQuery>;
export type LandsQueryResult = Apollo.QueryResult<LandsQuery, LandsQueryVariables>;
import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SummaryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SummaryQuery = { __typename?: 'query_root', me: Array<{ __typename?: 'me', id: string | null, balance: number | null, balance_at: import("dayjs").Dayjs | null, last_paid_amount: number | null, last_paid_at: any | null }>, account: Array<{ __typename?: 'account', id: string, number: string }> };


export const SummaryDocument = gql`
    query Summary {
  me {
    id
    balance
    balance_at
    last_paid_amount
    last_paid_at
  }
  account(distinct_on: id) {
    id
    number
  }
}
    `;

/**
 * __useSummaryQuery__
 *
 * To run a query within a React component, call `useSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSummaryQuery(baseOptions?: Apollo.QueryHookOptions<SummaryQuery, SummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummaryQuery, SummaryQueryVariables>(SummaryDocument, options);
      }
export function useSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummaryQuery, SummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummaryQuery, SummaryQueryVariables>(SummaryDocument, options);
        }
export type SummaryQueryHookResult = ReturnType<typeof useSummaryQuery>;
export type SummaryLazyQueryHookResult = ReturnType<typeof useSummaryLazyQuery>;
export type SummaryQueryResult = Apollo.QueryResult<SummaryQuery, SummaryQueryVariables>;